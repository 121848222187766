import React from 'react';
import {Ribbon} from './Ribbon';
import './Button.scss';

interface IButton {
  text: string;
  buttonStyle: string;
  action?: () => void;
}

export const Button = (props: IButton) => {
  const {text, buttonStyle, action} = props;

  return (
    <button className={`cmButton ${buttonStyle}`} onClick={action}>
      <div className="cmButton-text">{text}</div>
      <div className="cmButton-ribbon">
        <Ribbon />
      </div>
    </button>
  );
};
