import {useStore} from 'effector-react';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getFeaturedVisionQuery, getVisionItemsQuery} from './graphql/getVisionItems';
import {getContent} from '../../services/httpService';
import $appStore from '../../store/app/store';
import {Button} from '../Button';
import config from '../../config/config.json';
import {label} from '../../utils/getLabel';

import './Vision.scss';
import {getSlug} from '../../utils/utils';

interface IVision {
  visionMode: 'singleItem' | 'allItems';
  items?: any;
}

export const Vision = (props: IVision) => {
  const {visionMode, items} = props;

  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getVision = async () => {
      const query = `{
    ${visionMode === 'singleItem' ? getFeaturedVisionQuery : getVisionItemsQuery}
    }`;
      const vision: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );
      if (vision) setData(vision.data.queryVisionsofleadershipitemsContents);
    };

    if (items) {
      setData(items);
    } else {
      getVision();
    }
  }, [appStore.language, items, visionMode]);

  return (
    <>
      {data && data.length > 0 && (
        <div className="vision">
          <div className={`vision-container ${visionMode}`}>
            {data &&
              data.slice(0, visionMode === 'singleItem' ? 1 : 10).map((item: any, index: number) => (
                <div className="vision-item" key={index}>
                  <div className="vision-item-image">
                    <img src={`${config.assetsURL}/${item.flatData.image[0].slug}`} alt={item.flatData.title} />
                  </div>

                  <div className="vision-item-texts">
                    <div className="vision-item-texts-title">
                      {visionMode === 'singleItem' ? label('visions-on-leadership', appStore.language) : ''}
                    </div>
                    <div className="vision-item-texts-subtitle">{item.flatData.title}</div>
                    <div className="vision-item-texts-text" dangerouslySetInnerHTML={{__html: item.flatData.text}} />
                  </div>
                </div>
              ))}
            <div className={`vision-button ${visionMode === 'singleItem' ? 'singleItem' : ''}`}>
              <Link to={`/${appStore.language}/${getSlug('contact', appStore.slugs!)}`}>
                <Button text={appStore.contactSettings.buttonText} buttonStyle={`style-4`} />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
