export const getNavAndSettingsQuery = `
querySettingsContents {
  flatData {
    enableEn
    anniversaryLogo {
      slug
    }
  }
}
  queryHeadermenuorderContents {
    flatData {
      headerMenuItems {
        flatData {
          slug
          menuTitle
          submenuList
          type
          teamList {
            flatData {
              name
              friendlyUrl
            }
          }
          expertiseList {
            flatData {
              title
              friendlyUrl
            }
          }
          menuItems {
            itemTitle
            slug
            type
          }
        }
      }
    }
  }
  queryFootermenuContents {
    flatData {
      menuItems {
        itemTitle
        type
      }
    }
  }
  querySlugsContents {
    data {
      aboutSlug {
        en
        nl
      }
      casesSlug {
        en
        nl
      }
      marketsSlug {
        en
        nl
      }

      teamSlug {
        en
        nl
      }
      visionsSlug {
        en
        nl
      }
    }
  }
  queryContactsettingsContents {
    flatData {
      address
      email
      phone
      buttonText
    }
  }
`;
