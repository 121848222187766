import config from '../../../config/config.json';
import {Button} from '../../../components/Button';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';

import $appStore from '../../../store/app/store';
import {getSlug} from '../../../utils/utils';

import './SectionAbout.scss';
interface IData {
  data: any;
}

export const SectionAbout = (props: IData) => {
  const {data} = props;
  const appStore = useStore($appStore);

  return (
    <div className="sectionAbout">
      {appStore && (
        <div className="sectionAbout-inner">
          <div className="sectionAbout-inner-image">
            <img
              src={`${config.assetsURL}/${data.image[0].slug}?${appStore.isMobile ? 'width=350&' : ''}&cache=15552000`}
              alt={data.title}
            />
          </div>
          <div className="sectionAbout-inner-texts">
            <div className="sectionAbout-inner-texts-title">{data.title}</div>
            <div className="sectionAbout-inner-texts-subtitle">{data.subtitle}</div>
            <div className="sectionAbout-inner-texts-text" dangerouslySetInnerHTML={{__html: data.text}} />
            <div className="sectionAbout-inner-texts-button">
              <Link to={`/${appStore.language}/${getSlug('about', appStore.slugs!)}`}>
                <Button text={data.buttonText} buttonStyle={'style-2'} />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
