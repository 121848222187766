import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {getExpertiseContentQuery} from './graphql/getExpertiseContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';

import {SectionExpertise} from './components/SectionExpertise';
import {SectionMarkets} from './components/SectionMarkets';
import {ExpertiseDetails} from './ExpertiseDetails';
import {FeaturedCaseStudies} from '../../components/FeaturedCaseStudies/FeaturedCaseStudies';

import {setCurrentPage, setMetaDescription, setMetaImage, setMetaTitle} from '../../store/app/events';
import './Expertise.scss';

export const Expertise = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const {slug} = useParams();

  useEffect(() => {
    const getExpertiseContent = async () => {
      const query = `{
    ${getExpertiseContentQuery}
    }`;
      const expertise: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (expertise) {
        setData(expertise.data);
        if (!slug) {
          setMetaTitle(expertise.data.queryExpertisecontentContents[0].flatData.metaTitle);
          setMetaDescription(expertise.data.queryExpertisecontentContents[0].flatData.metaDescription);
        }
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    getExpertiseContent();
  }, [appStore.language, slug]);
  useEffect(() => {
    setCurrentPage('expertise');
  }, []);
  return (
    <div className="expertise">
      {data && !slug && (
        <div className="expertise-sectionExpertise">
          <SectionExpertise data={data.queryExpertisecontentContents[0].flatData} />
          {/* <SectionMarkets
            market={data.queryMarketscontentContents}
            marketItems={data.queryMarketsContents}
            relativeCases={data.queryCasestudiesContents}
          /> */}
          <FeaturedCaseStudies />
        </div>
      )}
      {slug && (
        <div className="expertise-sectionExpertiseItem">
          <ExpertiseDetails slug={slug} />
        </div>
      )}
    </div>
  );
};
