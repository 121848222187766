import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Grid, Navigation, Pagination} from 'swiper';
import $appStore from '../../../store/app/store';
import {getCaseStudiesQuery} from '../graphql/getCaseStudies';
import {getContent} from '../../../services/httpService';
import {Button} from '../../../components/Button';
import {label} from '../../../utils/getLabel';
import config from '../../../config/config.json';
import useWindowSize from '../../../hooks/useWindowSize';
import {Vision} from '../../../components';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './CaseStudiesIndex.scss';
import {getSlug} from '../../../utils/utils';
import {setMetaTitle, setMetaDescription} from '../../../store/app/events';
interface ICaseStudies {
  archive: boolean;
}
export const CaseStudiesIndex = (props: ICaseStudies) => {
  const {archive} = props;
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const [pages, setPages] = useState<any>();
  const {slug} = useParams();
  const [isArchive, setIsArchive] = useState(archive);
  const windowSize = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    const getCaseStudies = async () => {
      const query = `{
    ${getCaseStudiesQuery}
    }`;
      const caseStudies: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        'nl', //`${appStore.language}`,
      );

      if (caseStudies) {
        setData(caseStudies.data.queryCasestudiescontentContents[0].flatData);
        setMetaTitle(caseStudies.data.queryCasestudiescontentContents[0].flatData.metaTitle);
        setMetaDescription(caseStudies.data.queryCasestudiescontentContents[0].flatData.metaDescription);
      }
    };

    getCaseStudies();
  }, [appStore.language, archive]);

  useEffect(() => {
    if (
      data?.items.filter((e: any) =>
        archive ? e.flatData.archive === true : e.flatData.archive === false || e.flatData.archive === null,
      ).length > 0
    ) {
      let itemsPerPage = 9;
      if (windowSize.width > 1280) {
        itemsPerPage = 9;
      } else if (windowSize.width > 1024) {
        itemsPerPage = 4;
      } else if (windowSize.width > 768) {
        itemsPerPage = 4;
      } else if (windowSize.width < 767) {
        itemsPerPage = 10;
      }

      const totalPages = data.items.filter((e: any) =>
        archive ? e.flatData.archive === true : e.flatData.archive === false || e.flatData.archive === null,
      )
        ? Math.ceil(
            data.items.filter((e: any) =>
              archive ? e.flatData.archive === true : e.flatData.archive === false || e.flatData.archive === null,
            ).length / itemsPerPage,
          )
        : 0;
      const pagesArray = [];
      for (let i = 0; i < totalPages; i++) {
        const pageItems: any = [];

        data.items.filter((e: any) =>
          archive ? e.flatData.archive === true : e.flatData.archive === false || e.flatData.archive === null,
        ) &&
          data.items
            .filter((e: any) =>
              archive ? e.flatData.archive === true : e.flatData.archive === false || e.flatData.archive === null,
            )
            .slice(i * itemsPerPage, (i + 1) * itemsPerPage)
            .map((item: any) => {
              pageItems.push(item);
            });
        pagesArray.push({page: i, items: pageItems});
      }
      setPages(pagesArray);
    } else {
      setPages(null);
    }
  }, [data, windowSize.width, archive]);

  useEffect(() => {
    const casesArchiveLocation = getSlug('casesArchive', appStore.slugs!);
    const casesLocation = getSlug('cases', appStore.slugs!);
    if (location.pathname && casesArchiveLocation && casesLocation) {
      if (location.pathname.includes(casesArchiveLocation)) {
        setIsArchive(true);
      } else if (location.pathname.includes(casesLocation)) {
        setIsArchive(false);
      }
    } else {
      setIsArchive(false);
    }
  }, [location.pathname, appStore.language, appStore.slugs, archive]);

  return (
    <div className="caseStudiesIndex">
      {data && (
        <>
          <div className="caseStudiesIndex-container">
            <h1 className="caseStudiesIndex-title">
              {archive ? label('case-studies-archive', appStore.language) : data.title}
            </h1>
            <h2 className="caseStudiesIndex-text" dangerouslySetInnerHTML={{__html: data.text}} />
          </div>

          <Swiper
            slidesPerView={1}
            effect={'fade'}
            grid={{
              rows: 1,
              fill: 'row',
            }}
            spaceBetween={1}
            speed={800}
            modules={[Grid, Navigation, Pagination]}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            autoHeight={true}
            className="mySwiper caseStudiesIndex-container swiperContainer"
          >
            {pages &&
              pages !== null &&
              pages.length > 0 &&
              pages.map((page: any, index: number) => (
                <SwiperSlide key={index} className="caseStudiesIndex-items">
                  {page.items &&
                    page.items !== null &&
                    page.items.length > 0 &&
                    page.items.map((item: any, index: number) => (
                      <div key={index} className="caseStudiesIndex-items-item">
                        <Link
                          to={`/${appStore.language}/${getSlug(archive ? 'casesArchive' : 'cases', appStore.slugs!)}/${
                            item.flatData.friendlyUrl
                          }`}
                          className="caseStudiesIndex-items-item-body"
                          key={index}
                        >
                          <div className="caseStudiesIndex-items-item-body-caseStudy">
                            {label('case-study', appStore.language)}
                          </div>
                          <div className="caseStudiesIndex-items-item-body-title">{item.flatData.title}</div>
                          <div className="caseStudiesIndex-items-item-body-inner">
                            <div
                              className="caseStudiesIndex-items-item-body-shortDescription"
                              dangerouslySetInnerHTML={{__html: item.flatData.shortDescription}}
                            />
                            <div className="caseStudiesIndex-items-item-body-sections">
                              {/* <div className="caseStudiesIndex-items-item-body-sections-section">
                              <div className="caseStudiesIndex-items-item-body-sections-section-title">
                                {label('title-market', appStore.language)}
                              </div>
                              <div className="caseStudiesIndex-items-item-body-sections-section-text">
                                {item.flatData.markets.map((marketItem: any, index: number) => (
                                  <span key={index}>{marketItem.flatData.title}</span>
                                ))}
                              </div>
                            </div> */}
                              {item.flatData.expertise && (
                                <div className="caseStudiesIndex-items-item-body-sections-section">
                                  <div className="caseStudiesIndex-items-item-body-sections-section-title">
                                    {label('title-expertise', appStore.language)}
                                  </div>
                                  <div className="caseStudiesIndex-items-item-body-sections-section-text">
                                    {item.flatData.expertise.map((expertiseItem: any, index: number) => (
                                      <span key={index}>{expertiseItem.flatData.title}</span>
                                    ))}
                                  </div>
                                </div>
                              )}
                              <div className="caseStudiesIndex-items-item-body-sections-section">
                                <div className="caseStudiesIndex-items-item-body-sections-section-title">
                                  {label('lead-time', appStore.language)}
                                </div>
                                <div className="caseStudiesIndex-items-item-body-sections-section-text">
                                  {item.flatData.leadTime}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="caseStudiesIndex-items-item-footer">
                          <div className="caseStudiesIndex-items-item-footer-ribbon">
                            <svg className="icon">
                              <use xlinkHref="#icon-ribbon" />
                            </svg>
                          </div>
                          <Link
                            to={`/${appStore.language}/${getSlug(
                              archive ? 'casesArchive' : 'cases',
                              appStore.slugs!,
                            )}/${item.flatData.friendlyUrl}`}
                            className="caseStudiesIndex-items-item-footer-arrow"
                          >
                            <svg className="icon">
                              <use xlinkHref="#icon-arrow" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    ))}
                </SwiperSlide>
              ))}
          </Swiper>
        </>
      )}
      <Link
        to={`/${appStore.language}/${getSlug(archive ? 'cases' : 'casesArchive', appStore.slugs!)}`}
        className="caseStudiesIndex-container"
      >
        <div className="caseStudiesIndex-button">
          <Button
            text={label(archive ? 'case-studies' : 'case-studies-archive', appStore.language)}
            buttonStyle={'style-7'}
          />
        </div>
      </Link>
      <Vision visionMode={'singleItem'} />
    </div>
  );
};
