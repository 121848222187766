import {Link} from 'react-router-dom';

import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import config from '../../../config/config.json';
import {getSlug} from '../../../utils/utils';

import {label} from '../../../utils/getLabel';
import {useEffect, useState} from 'react';
import {getContent} from '../../../services/httpService';
import {getTeamArticlesQuery} from '../graphql/getTeamPageContent';

import './TeamMember.scss';
import {setRef} from '../../../store/app/events';

interface ITeamMember {
  data: any;
}

export const TeamMember = (props: ITeamMember) => {
  const {data} = props;
  const appStore = useStore($appStore);
  const [memberArticles, setMemberArticles] = useState([]);

  useEffect(() => {
    const getTeamArticleContent = async () => {
      const query = `{
    ${getTeamArticlesQuery.replace('#ID#', data.id)}
    }`;
      const articles: any = await getContent('POST', `api/content/${config.appName}/graphql`, {query}, 'nl');
      setMemberArticles(articles.data.queryArticlesitemsContents);
    };
    getTeamArticleContent();
    setRef(null);
  }, [data.id]);
  const sortAlpha = (list: any) => {
    list.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return list;
  };
  return (
    <div className="team-container  teamMember">
      <Link to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}`} className="header-back">
        <div className="header-back-icon">
          <svg className="icon">
            <use xlinkHref="#icon-arrow" />
          </svg>
        </div>
        <span>Custom Management Team</span>
      </Link>
      <div className="teamMember-top">
        <div className="teamMember-top-leftCol">
          <div className="teamMember-top-leftCol-image">
            {data.flatData.image && data.flatData.image.length > 0 && (
              <img src={`${config.assetsURL}/${data.flatData.image[0].slug}?width=500`} alt={data.flatData.name} />
            )}
          </div>
          <div className="mobileCol">
            <div className="teamMember-top-leftCol-social">
              <a
                href={data.flatData.linkedIn}
                target="_blank"
                className="teamMember-top-leftCol-social-icon icon-linkedIn"
                rel="noreferrer"
              >
                <svg className="icon">
                  <use xlinkHref="#icon-linkedIn" />
                </svg>
              </a>
              <a href={`mailto:${data.flatData.email}`} className="teamMember-top-leftCol-social-icon icon-email">
                <svg className="icon">
                  <use xlinkHref="#icon-email" />
                </svg>
              </a>
              <a href={`tel:${data.flatData.phone}`} className="teamMember-top-leftCol-social-icon icon-phone">
                <svg className="icon">
                  <use xlinkHref="#icon-phone" />
                </svg>
              </a>
            </div>
            <div
              className="teamMember-top-leftCol-shortText"
              dangerouslySetInnerHTML={{__html: `"${data.flatData.shortText}"`}}
            />
          </div>
          {data.flatData.referencesImagesWName && data.flatData.referencesImagesWName.length > 0 && (
            <div className="teamMember-top-leftCol-references">
              <div className="teamMember-top-leftCol-references-inner">
                {sortAlpha(data.flatData.referencesImagesWName).map((item: any, index: number) => (
                  <div className="teamMember-top-leftCol-references-inner-image" key={index}>
                    {item.image && item.image.length > 0 && (
                      <img src={`${config.assetsURL}/${item.image[0].slug}?width=200`} alt={`${item.name}`} />
                    )}
                  </div>
                ))}
              </div>
              {/* <div className="teamMember-top-leftCol-referencesTitle">{data.flatData.referencesText}</div> */}
            </div>
          )}
          {data.flatData.referencesImagesWNameOther && data.flatData.referencesImagesWNameOther.length > 0 && (
            <div className="teamMember-top-leftCol-references">
              <div className="teamMember-top-leftCol-references-inner">
                {sortAlpha(data.flatData.referencesImagesWNameOther).map((item: any, index: number) => (
                  <div className="teamMember-top-leftCol-references-inner-image" key={index}>
                    {item.image && item.image.length > 0 && (
                      <img src={`${config.assetsURL}/${item.image[0].slug}?width=200`} alt={`${item.name}`} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="teamMember-top-rightCol">
          <h1 className="teamMember-top-rightCol-name">{data.flatData.name}</h1>
          <h2 className="teamMember-top-rightCol-title">{data.flatData.title}</h2>
          <div className="teamMember-top-rightCol-text" dangerouslySetInnerHTML={{__html: data.flatData.text}} />
        </div>
      </div>
      <div className="teamMember-bottom">
        <div className="teamMember-bottom-leftCol"></div>
        {appStore.language === 'nl' && memberArticles && memberArticles.length > 0 && (
          <div className="teamMember-bottom-rightCol">
            <div className="teamMember-bottom-rightCol-title">
              {label('articles-all-by', appStore.language)} {data.flatData.name}
            </div>
            <div className="teamMember-bottom-rightCol-articleList">
              {memberArticles.map((item: any, index: number) => (
                <Link
                  to={`/${appStore.language}/${getSlug('articles', appStore.slugs!)}/${item.flatData.friendlyUrl}`}
                  className="teamMember-bottom-rightCol-articleList-item"
                  key={index}
                  onClick={() => setRef({ref: window.location.href, name: data.flatData.name})}
                >
                  {item.flatData.title}
                </Link>
              ))}
            </div>
            <Link
              to={`/${appStore.language}/${getSlug('articles', appStore.slugs!)}`}
              className="teamMember-bottom-rightCol-link"
            >
              <div className="teamMember-bottom-rightCol-link-text">{label('articles-all', appStore.language)}</div>
              <div className="teamMember-bottom-rightCol-link-icon">
                <svg className="icon">
                  <use xlinkHref="#icon-arrow" />
                </svg>
              </div>
            </Link>

            {/* <div className="teamMember-bottom-rightCol-related">
            <div className="teamMember-bottom-rightCol-related-button">
            <div className="teamMember-bottom-rightCol-related-button-text">Related Case Studies</div>
            <div className="teamMember-bottom-rightCol-related-button-icon">
            <svg className="icon">
            <use xlinkHref="#icon-arrow-down" />
            </svg>
            </div>
            </div>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
