import useOnClickOutside from '../../hooks/useOnClickOutside';
import {useEffect, useRef, useState} from 'react';
import $appStore from '../../store/app/store';
import {useStore} from 'effector-react';
import {setIsModalOpen} from '../../store/app/events';
import conf from '../../config/config.json';

import {getContent} from '../../services/httpService';
import {Field, Form, Formik} from 'formik';
import {label} from '../../utils/getLabel';
import {Ribbon} from '../Button/Ribbon';
import axios from 'axios';

import './Modal.scss';
export const Modal = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    appStore.isModalOpen && setIsModalOpen(false);
  };

  useOnClickOutside(modalRef, handleClickOutside);

  useEffect(() => {
    const getNewsletterContent = async () => {
      const query = `{queryNewsletterContents { flatData { title text name buttonText successMessage }}}`;
      const newsletter: any = await getContent(
        'POST',
        `api/content/${conf.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (newsletter) {
        setData(newsletter.data.queryNewsletterContents[0].flatData);
      }
    };

    getNewsletterContent();
  }, [appStore.language]);

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    const url = `https://cms.greymatter.gr/api/content/custom-management/newsletterusers/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', 'custom-management:default');
    params.append('client_secret', 'kctszq1nmpl42lw4gxiaifxembxj9okeomyj9ii8wryx');
    params.append('scope', 'squidex-api');

    axios({
      method: 'post',
      url: 'https://cms.greymatter.gr/identity-server/connect/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        console.log(values);
        const data = {
          email: values['newsletterEmail'],
          name: values['newsletterFullName'],
          consent: values['consentToTrack'] === true ? {iv: 'Yes'} : {iv: 'No'},
        };
        axios
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setSubmitting(false);
            if (response) {
              resetForm();
              setIsFormSuccess(true);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleValidation = (values: any) => {
    const errors: any = {};
    const notRequiredFields = ['newsletterFullName', 'consentToTrack'];

    for (const key in values) {
      const value = `${values[key]}`;

      if (!value && !notRequiredFields.includes(key)) {
        errors[key] = label('frmRequired', appStore.language);
      }
    }

    return errors;
  };
  return (
    <>
      {appStore.isModalOpen && data && (
        <div className={`modal newsletter`}>
          <div ref={modalRef} className="modal-inner">
            <div className="close" onClick={() => setIsModalOpen(false)}></div>
            {isFormSuccess && (
              <div className="successMessage">
                <div className="newsletter-form-title">{data.title}</div>
                <div className="newsletter-form-text" dangerouslySetInnerHTML={{__html: data.successMessage}} />
              </div>
            )}
            {!isFormSuccess && (
              <Formik
                initialValues={{
                  newsletterFullName: '',
                  newsletterEmail: '',
                  consentToTrack: '',
                }}
                validate={(values) => {
                  return handleValidation(values);
                }}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  onSubmit(values, setSubmitting, resetForm);
                }}
              >
                {({errors, touched, handleChange, isSubmitting}) => (
                  <Form id="newsletter-form" className="form">
                    <div className="newsletter-form-title">{data.title}</div>
                    <div className="newsletter-form-text">{data.text}</div>
                    <div className="form-fields">
                      <div className=" ">
                        <div
                          className={`form-control ${
                            touched.newsletterFullName && errors.newsletterFullName ? 'has-error' : ''
                          }`}
                        >
                          <Field
                            type="text"
                            name="newsletterFullName[iv]"
                            id="newsletterFullName"
                            placeholder={label('frmFName', appStore.language)}
                          />
                          <label htmlFor="newsletterFullName">{label('frmFName', appStore.language)}</label>
                          {touched.newsletterFullName && errors.newsletterFullName && (
                            <p className="error-message">{errors.newsletterFullName}</p>
                          )}
                        </div>
                      </div>
                      <div className=" ">
                        <div
                          className={`form-control ${
                            touched.newsletterEmail && errors.newsletterEmail ? 'has-error' : ''
                          }`}
                        >
                          <Field type="text" name="newsletterEmail[iv]" id="newsletterEmail" placeholder={'email'} />
                          <label htmlFor="newsletterEmail">{label('email', appStore.language)}</label>
                          {touched.newsletterEmail && errors.newsletterEmail && (
                            <p className="error-message">{errors.newsletterEmail}</p>
                          )}
                        </div>
                      </div>
                      <div className=" ">
                        <div
                          className={`form-control checkbox ${
                            touched.consentToTrack && errors.consentToTrack ? 'has-error' : ''
                          }`}
                        >
                          <Field
                            type="checkbox"
                            className="inputCheckbox"
                            name="consentToTrack[iv]"
                            id="consentToTrack"
                          />
                          <label htmlFor="consentToTrack">{label('consent', appStore.language)}</label>
                          {touched.consentToTrack && errors.consentToTrack && (
                            <p className="error-message">{errors.consentToTrack}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-action">
                      <button className={`cmButton style-1`} type="submit">
                        <div className="cmButton-text">{data.buttonText}</div>
                        <div className="cmButton-ribbon">
                          <Ribbon />
                        </div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </>
  );
};
