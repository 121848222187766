import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

import {getExpertiseDetailQuery} from './graphql/getExpertiseContent';
import config from '../../config/config.json';

import {getContent} from '../../services/httpService';

import {RelatedCaseStudies} from '../../components';
import {Link} from 'react-router-dom';
import {getSlug} from '../../utils/utils';
import {setMetaTitle, setMetaDescription, setMetaImage} from '../../store/app/events';

import './ExpertiseDetails.scss';
interface IexpertiseDetails {
  slug: string;
}
export const ExpertiseDetails = (props: IexpertiseDetails) => {
  const {slug} = props;
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getExpertiseDetailsContent = async () => {
      const query = `{
    ${getExpertiseDetailQuery.replace('#CURRENT_LANGUAGE#', appStore.language).replace('#SLUG#', slug)}
    }`;
      const expertiseDetails: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (
        expertiseDetails &&
        expertiseDetails.data.queryExpertiseContents &&
        expertiseDetails.data.queryExpertiseContents[0] &&
        expertiseDetails.data.queryExpertiseContents[0].flatData
      ) {
        setData(expertiseDetails.data.queryExpertiseContents[0].flatData);
        if (expertiseDetails.data.queryExpertiseContents[0].flatData.metaTitle !== null)
          setMetaTitle(expertiseDetails.data.queryExpertiseContents[0].flatData.metaTitle);
        if (expertiseDetails.data.queryExpertiseContents[0].flatData.metaDescription !== null)
          setMetaDescription(expertiseDetails.data.queryExpertiseContents[0].flatData.metaDescription);
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    getExpertiseDetailsContent();
  }, [appStore.language, slug]);

  return (
    <div className="expertiseDetails">
      {data && (
        <>
          <div className="expertiseDetails-container">
            <Link to={`/${appStore.language}/${getSlug('expertise', appStore.slugs!)}`} className="header-back">
              <div className="header-back-icon">
                <svg className="icon">
                  <use xlinkHref="#icon-arrow" />
                </svg>
              </div>
              <span>Expertise</span>
            </Link>
            <h1 className="expertiseDetails-title">{data.title}</h1>
            <h2 className="expertiseDetails-leadText">{data.leadPhraseDetails}</h2>
            <div className="expertiseDetails-sections">
              {data &&
                data.texts &&
                data.texts.length > 0 &&
                data.texts.map((item: any, index: number) => (
                  <div className={`expertiseDetails-sections-item ${item.style}`} key={index}>
                    {item.image && (
                      <div className="expertiseDetails-sections-item-image">
                        <img src={`${config.assetsURL}/${item.image[0].slug}?width=1000`} alt={item.title} />
                      </div>
                    )}
                    <div
                      className="expertiseDetails-sections-item-text"
                      dangerouslySetInnerHTML={{__html: item.text}}
                    />
                  </div>
                ))}
            </div>
          </div>
          <RelatedCaseStudies type={'expertise'} id={data.id} color="grey" />
        </>
      )}
    </div>
  );
};
