export const getAboutQuery = `queryAboutContents {
    flatData {
      title
      subtitle
      text
      backgroundImage {
        slug
      }
      aboutTitle
      aboutText
      aboutImage {
        slug
      }
      coreTitle
      coreText
      teamTitle
      teamText
      teamButtonText
      teamImage {
        slug
      }
      teamBackgroundImage {
        slug
      }
      metaTitle
      metaDescription
    }
  }`;
