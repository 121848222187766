import { SlugTypes } from "../store/app/types";

export const sanitizeUppercase = (string: string) => {
  let sanitizedString = "";

  for (let i = 0; i < string.length; i++) {
    sanitizedString += string[i]
      .replace(/ά|Ά/g, "α")
      .replace(/έ|Έ/g, "ε")
      .replace(/ή|Ή/g, "η")
      .replace(/ί|Ί/g, "ι")
      .replace(/ό|Ό/g, "ο")
      .replace(/ύ|Ύ/g, "υ")
      .replace(/ώ|Ώ/g, "ω")
      .replace(/ς/g, "σ");
  }

  return sanitizedString.toUpperCase();
};
export const shuffleArray = (array: any[], all: boolean) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return all ? array : array[0];
};

export const getSlug = (name: string, types: SlugTypes) => {
  let slug = "";
  switch (name) {
    case "about":
      slug = types.about;
      break;
    case "team":
      slug = types.team;
      break;
    case "articles":
      slug = types.articles;
      break;
    case "articlesArchive":
      slug = types.articlesArchive;
      break;
    case "cases":
      slug = types.cases;
      break;
    case "casesArchive":
      slug = types.casesArchive;
      break;
    case "clients":
      slug = types.clients;
      break;
    case "contact":
      slug = types.contact;
      break;
    case "expertise":
      slug = types.expertise;
      break;
    case "markets":
      slug = types.markets;
      break;
    case "visions":
      slug = types.visions;
      break;
    case "visionsArchive":
      slug = types.visionsArchive;
      break;
    case "terms":
      slug = types.terms;
      break;
    case "privacy":
      slug = types.privacy;
      break;
    case "content page":
      slug = "page";
      break;
    default:
      slug = "";
  }
  return slug;
};

export const getParentWidth = (element: string) => {
  const parentElement = document.querySelector(`.${element}`) as HTMLDivElement;
  if (parentElement != null) {
    return parentElement.offsetWidth as number;
  }
};
