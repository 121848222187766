import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Loader} from '../Loader';

import {Modal} from '../Modal/Modal';
import './Layout.scss';

export const Layout = () => {
  const appStore = useStore($appStore);

  return (
    <div className={`layout  ${appStore.isLoading ? 'loading' : ''} ${appStore.isMobile ? 'isMobile' : ''}`}>
      <Header />
      <div className={`main`}>
        <Outlet />
      </div>
      <Footer />
      <Modal />
      {appStore.isLoading && <Loader />}
    </div>
  );
};
