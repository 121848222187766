import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {getTeamContentQuery} from './graphql/getTeamPageContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';

import {TeamIndex} from './components/TeamIndex';
import {TeamMember} from './components/TeamMember';
import {
  setCurrentPage,
  setHeaderIsHidden,
  setMetaDescription,
  setMetaImage,
  setMetaTitle,
} from '../../store/app/events';

import './Team.scss';

export const Team = () => {
  const appStore = useStore($appStore);
  const {slug} = useParams();
  const [data, setData] = useState<any>();
  const [member, setMember] = useState<any>();

  useEffect(() => {
    const getTeamContent = async () => {
      const query = `{
    ${getTeamContentQuery}
    }`;
      const team: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );
      let data = [];
      data = team.data.queryTeamContents[0];

      // data.articles = team.data.queryTeamContents[0].referencingArticlesitemsContents;
      if (team) {
        setData(data);
        setMetaTitle(team.data.queryTeamContents[0].flatData.metaTitle);
        setMetaDescription(team.data.queryTeamContents[0].flatData.metaDescription);
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    if (!data) getTeamContent();
  }, [data, appStore.language]);

  useEffect(() => {
    if (data && slug) {
      const memberData = data.flatData.members.filter((item: any) => item.flatData.friendlyUrl === slug);
      if (memberData.length > 0) {
        // memberData[0].flatData.articles = memberData[0].referencingArticlesitemsContents;
        setMember(memberData[0]);
        setMetaTitle(memberData[0].flatData.metaTitle);
        setMetaDescription(memberData[0].flatData.metaDescription);
        setHeaderIsHidden(false);
        if (memberData[0].flatData.image && memberData[0].flatData.image.length > 0) {
          setMetaImage(`${config.assetsURL}/${memberData[0].flatData.image[0].slug}`);
        }
      }
    }
  }, [data, slug]);

  useEffect(() => {
    setCurrentPage('team');
  }, []);

  return (
    <div className="team">
      {data && !slug && <TeamIndex data={data.flatData} />}
      {member && slug && <TeamMember data={member} />}
    </div>
  );
};
