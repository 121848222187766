import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import {getArticlesDetailsQuery} from '../graphql/getArticles';
import {getContent} from '../../../services/httpService';
import config from '../../../config/config.json';
import {label} from '../../../utils/getLabel';
import {Share} from './Share';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {getSlug} from '../../../utils/utils';
import {setMetaTitle, setMetaDescription, setMetaImage, setRef} from '../../../store/app/events';

import './ArticlesDetails.scss';

interface IArticlesDetails {
  slug: string;
}

export const ArticlesDetails = (props: IArticlesDetails) => {
  const {slug} = props;
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [allArticles, setAllArticles] = useState<any>();
  const [totalArticles, setTotalArticles] = useState<number>(0);
  const location = useLocation();
  const [isArchive, setIsArchive] = useState(false);
  // const prevLocation = usePrevLocation(location);
  const navigate = useNavigate();

  useEffect(() => {
    const getArticlesDetailsContent = async () => {
      const query = `{
    ${getArticlesDetailsQuery
      .replace('#CURRENT_LANGUAGE#', 'nl')
      .replace('#SLUG#', slug)
      .replace('#ARCHIVE#', isArchive ? 'true' : 'false')}
    }`;
      const articlesDetails: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        'nl', // `${appStore.language}`,
      );
      if (articlesDetails && articlesDetails.data.queryArticlesitemsContents[0].flatData) {
        setData(articlesDetails.data.queryArticlesitemsContents[0].flatData);
        articlesDetails.data.queryArticlesitemsContents[0].flatData.metaTitle !== null
          ? setMetaTitle(articlesDetails.data.queryArticlesitemsContents[0].flatData.metaTitle)
          : setMetaTitle(articlesDetails.data.queryArticlesitemsContents[0].flatData.title);
        setMetaDescription(articlesDetails.data.queryArticlesitemsContents[0].flatData.metaDescription);
      }

      setTotalArticles(articlesDetails.data.queryArticlesitemsContentsWithTotal.items.length);
      setAllArticles(articlesDetails.data.queryArticlesitemsContentsWithTotal.items);
      articlesDetails.data.queryArticlesitemsContentsWithTotal.items.map((e: any, index: number) => {
        if (e.flatData.friendlyUrl === slug) {
          setCurrentIndex(index);
        }
      });
    };
    setMetaImage(`${window.location.origin}/images/cm.png`);

    getArticlesDetailsContent();
  }, [appStore.language, slug, isArchive]);

  const moveToCase = (moveTo: string) => {
    if (data && slug && (currentIndex > 0 || currentIndex < totalArticles)) {
      if (moveTo === 'next') {
        if (currentIndex < totalArticles - 1) {
          navigate(
            `/${appStore.language}/${getSlug('articles', appStore.slugs!)}/${
              allArticles[currentIndex + 1].flatData.friendlyUrl
            }`,
          );
          setCurrentIndex(currentIndex + 1);
        }
      }
      if (moveTo === 'previous') {
        if (currentIndex > 0) {
          navigate(
            `/${appStore.language}/${getSlug('articles', appStore.slugs!)}/${
              allArticles[currentIndex - 1].flatData.friendlyUrl
            }`,
          );
          setCurrentIndex(currentIndex - 1);
        }
      }
    }
  };
  useEffect(() => {
    const articlesArchiveLocation = getSlug('articlesArchive', appStore.slugs!);
    const articlesLocation = getSlug('articles', appStore.slugs!);
    if (location.pathname && articlesArchiveLocation && articlesLocation) {
      if (location.pathname.includes(articlesArchiveLocation)) {
        setIsArchive(true);
      } else if (location.pathname.includes(articlesLocation)) {
        setIsArchive(false);
      }
    } else {
      setIsArchive(false);
    }
  }, [location.pathname, appStore.language, appStore.slugs]);

  return (
    <div className="articlesDetails">
      <div className="articlesDetails-content">
        {data && (
          <div className="articlesDetails-content-container">
            <div className="articlesDetails-content-row articlesDetails-content-header">
              <Link
                to={
                  appStore.ref === null
                    ? `/${appStore.language}/${getSlug(isArchive ? 'articlesArchive' : 'articles', appStore.slugs!)}`
                    : appStore.ref.ref
                }
                onClick={() => setRef(null)}
                className="articlesDetails-content-article"
              >
                <div className="articlesDetails-content-article-icon">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
                <span>
                  {appStore.ref === null
                    ? label(isArchive ? 'articles-archive' : 'articles', appStore.language)
                    : appStore.ref.name}
                </span>
              </Link>
              <div className="articlesDetails-content-share">
                <Share title={data.title} />
              </div>
            </div>
            <div className="articlesDetails-content-top articlesDetails-content-row">
              <h1 className="articlesDetails-content-top-title">{data.title}</h1>
              <div className="articlesDetails-content-top-share">
                <Share title={data.title} />
              </div>
            </div>
            <div className="articlesDetails-content-main articlesDetails-content-row">
              <div className="articlesDetails-content-main-sections">
                <div className="articlesDetails-content-main-sections-item">
                  <div
                    className="articlesDetails-content-main-sections-item-text introText"
                    dangerouslySetInnerHTML={{__html: data.indexDescription}}
                  />
                </div>
                {data.textSections.map((item: any, index: number) => (
                  <div key={index} className="articlesDetails-content-main-sections-item">
                    {item.sectionTitle && (
                      <div className="articlesDetails-content-main-sections-item-title">{item.sectionTitle}</div>
                    )}

                    {item.sectionText && (
                      <div
                        className="articlesDetails-content-main-sections-item-text"
                        dangerouslySetInnerHTML={{__html: item.sectionText}}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="articlesDetails-content-main-info">
                {data.author.length > 0 && (
                  <div className="articlesDetails-content-main-info-box">
                    {data.author.length > 0 &&
                      data.author.flatData &&
                      data.author.flatData.image &&
                      data.author.flatData.image.length > 0 && (
                        <div className="articlesDetails-content-main-info-box-image">
                          <img
                            src={`${config.assetsURL}/${data.author[0].flatData.image[0].slug}?width=350`}
                            alt={data.author[0].flatData.name}
                          />
                        </div>
                      )}

                    <div className="articlesDetails-content-main-info-box-texts">
                      <div className="articlesDetails-content-main-info-box-texts-name">
                        {data.author[0].flatData.name}
                      </div>
                      <div className="articlesDetails-content-main-info-box-texts-title">
                        {data.author[0].flatData.title}
                      </div>
                      <div className="articlesDetails-content-main-info-box-texts-social">
                        <a
                          href={data.author[0].flatData.linkedIn}
                          target="_blank"
                          className="articlesDetails-content-main-info-box-texts-social-icon icon-linkedIn"
                          rel="noreferrer"
                        >
                          <svg className="icon">
                            <use xlinkHref="#icon-linkedIn" />
                          </svg>
                        </a>
                        <a
                          href={`mailto:${data.author[0].flatData.email}`}
                          className="articlesDetails-content-main-info-box-texts-social-icon icon-email"
                        >
                          <svg className="icon">
                            <use xlinkHref="#icon-email" />
                          </svg>
                        </a>
                        <a
                          href={`tel:${data.author[0].flatData.phone}`}
                          className="articlesDetails-content-main-info-box-texts-social-icon icon-phone"
                        >
                          <svg className="icon">
                            <use xlinkHref="#icon-phone" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="articlesDetails-content-preFooter articlesDetails-content-row">
              <div className="articlesDetails-content-preFooter-ribbon">
                <svg className="icon">
                  <use xlinkHref="#icon-ribbon" />
                </svg>
              </div>

              <div className="articlesDetails-content-preFooter-share">
                <Share title={data.title} />
              </div>
            </div>
            <div className="articlesDetails-content-footer articlesDetails-content-row">
              <div
                className={`articlesDetails-content-footer-navigation ${currentIndex > 0 ? '' : 'disabled'}`}
                onClick={() => {
                  moveToCase('previous');
                }}
              >
                <div className="articlesDetails-content-footer-navigation-icon previous">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
                <span className="default">{label('previous-article', appStore.language)}</span>
                <span className="mobile">{label('previous', appStore.language)}</span>
              </div>

              <div
                className={`articlesDetails-content-footer-navigation ${
                  currentIndex === totalArticles - 1 ? 'disabled' : ''
                }`}
                onClick={() => {
                  moveToCase('next');
                }}
              >
                <span className="default">{label('next-article', appStore.language)}</span>
                <span className="mobile">{label('next', appStore.language)}</span>{' '}
                <div className="articlesDetails-content-footer-navigation-icon">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="articlesDetails-button">
        <Link
          to={`/${appStore.language}/${getSlug(isArchive ? 'articles' : 'articlesArchive', appStore.slugs!)}`}
          className="articlesIndex-container"
        >
          <div className="articlesIndex-button">
            <Button
              text={label(isArchive ? 'articles' : 'articles-archive', appStore.language)}
              buttonStyle={'style-7'}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};
