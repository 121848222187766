import {AppDomain} from './domain';
import {Language, SlugTypes, RefType} from './types';

export const setLanguage = AppDomain.createEffect<Language, Language>();
export const setNavIsOpen = AppDomain.createEvent<boolean>();
export const setIsModalOpen = AppDomain.createEvent<boolean>();
export const setFooterIsDark = AppDomain.createEvent<boolean>();
export const setIsMobile = AppDomain.createEvent<boolean>();
export const setIsLoading = AppDomain.createEvent<boolean>();
export const setIsDark = AppDomain.createEvent<boolean>();
export const setIsRoutesLoaded = AppDomain.createEvent<boolean>();
export const setHeaderIsHidden = AppDomain.createEvent<boolean>();
export const setLanguages = AppDomain.createEvent<any>();
export const setRoutes = AppDomain.createEvent<any>();
export const setFooterRoutes = AppDomain.createEvent<any>();
export const setContactSettings = AppDomain.createEvent<any>();
export const setAnniversaryLogo = AppDomain.createEvent<any>();
export const setCurrentPage = AppDomain.createEvent<string>();
export const setTitle = AppDomain.createEvent<string>();
export const setDescription = AppDomain.createEvent<string>();
export const setRef = AppDomain.createEvent<RefType | null>();
export const setEnableEn = AppDomain.createEvent<boolean>();
export const setSlugs = AppDomain.createEvent<SlugTypes>();
export const setGtm = AppDomain.createEvent<string>();
export const setMetaTitle = AppDomain.createEvent<string>();
export const setMetaDescription = AppDomain.createEvent<string>();
export const setMetaImage = AppDomain.createEvent<string>();
