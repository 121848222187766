import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {getContent} from '../../services/httpService';
import {useStore} from 'effector-react';
import {$appStore} from '../../store/app/store';
import config from '../../config/config.json';
import {getPageQuery} from './graphql/getPage';
import './Page.scss';
import {setMetaTitle, setMetaDescription, setMetaImage} from '../../store/app/events';
export const Page = () => {
  const {slug} = useParams();
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getPageContent = async (slug: string) => {
      const query = `{
    ${getPageQuery.replace('#SLUG#', slug).replace('#LANGUAGE#', appStore.language)}
    }`;
      const page: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );
      if (page) {
        setData(page.data.queryPagesContents[0]?.flatData);
        if (page.data.queryPagesContents[0]?.flatData.metaTitle !== null)
          setMetaTitle(page.data.queryPagesContents[0]?.flatData.metaTitle);
        if (page.data.queryPagesContents[0]?.flatData.metaDescription !== null)
          setMetaDescription(page.data.queryPagesContents[0]?.flatData.metaDescription);
      }
    };

    if (slug) {
      getPageContent(slug);
    }
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);
  }, [appStore.language, slug]);

  return (
    <div className="page">
      <div className="page-content">
        {data && (
          <div className="page-content-container">
            <h1 className="title">{data.title}</h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data.text}} />
          </div>
        )}
      </div>
    </div>
  );
};
