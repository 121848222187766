export const getRelativeCaseStudiesQuery = `
  queryCasestudiesContents {
    flatData {
      title
      friendlyUrl
      #TYPE# {
        id
      }
    }
  }
`;

export const getRelativeExpertiseCaseStudiesQuery = `
   queryCasestudiesContents {
    flatData {
      title
      friendlyUrl
      expertise {
        id
      }
    }
  }
`;
