import axios from 'axios';
import {setIsLoading} from '../store/app/events';
import config from '../config/config.json';

const getBearerToken = () => {
  return localStorage.getItem('token');
};

const setBearerToken = (token: string) => {
  localStorage.setItem('token', token);
};

const clearBearerToken = () => {
  localStorage.removeItem('token');
};

export const fetchBearerToken = async () => {
  let token = getBearerToken();

  if (token) {
    return token;
  }

  const data = `grant_type=client_credentials&scope=squidex-api&client_id=${config.clientId}&client_secret=${config.clientSecret}`;

  const response = await axios(buildUrl('identity-server/connect/token'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  });

  if (response.statusText !== 'OK') {
    throw new Error(`Failed to retrieve token, got ${response.statusText}`);
  }

  const json = await response.data;
  token = json.access_token;
  token && setBearerToken(token);

  return token;
};

export const getContent = async (method: 'POST' | 'GET' | 'PUT', url: string, data: any, language: string) => {
  setIsLoading(true);
  const token = await fetchBearerToken();
  let responseData: any;

  if (token) {
    await axios(buildUrl(url), {
      method: method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Languages': language,
      },
      data: data ? JSON.stringify(data) : null,
    })
      .then((response) => {
        responseData = response.data;
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          clearBearerToken();
        }
      });
    return responseData;
  }
};

function buildUrl(url: any) {
  if (url.length > 0 && url.startsWith('/')) {
    url = url.substr(1);
  }

  const result = `${config.apiURL}/${url}`;

  return result;
}
