import {HelmetProvider} from 'react-helmet-async';

import ScrollToTop from './utils/ScrollToTop';

import {Seo, CacheBuster} from './components';

import {AppRoutes} from './routes/AppRoutes';

import {ReactComponent as UISvg} from './assets/ui.svg';

import './styles/main.scss';

function App() {
  const helmetContext = {};

  return (
    <CacheBuster>
      <HelmetProvider context={helmetContext}>
        <Seo />
        <div className="App">
          <AppRoutes />
          <UISvg />
          {/* <Version /> */}
          <ScrollToTop />
        </div>
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;
