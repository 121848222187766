import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import AnimatedNumbers from 'react-animated-numbers';
import useWindowSize from '../../../hooks/useWindowSize';

import config from '../../../config/config.json';

import './SectionStatistics.scss';

interface IData {
  data: any;
}

export const SectionStatistics = (props: IData) => {
  const {data} = props;
  const appStore = useStore($appStore);

  const {width} = useWindowSize();
  const [windowWidth, setWindowWidth] = useState(width);
  const [refreshStatistics, setRefreshStatistics] = useState(false);

  useEffect(() => {
    if (width !== windowWidth) {
      setRefreshStatistics(true);
      setWindowWidth(width);
      setTimeout(() => {
        setRefreshStatistics(false);
      }, 400);
    }
    setWindowWidth(width);
  }, [windowWidth, width]);

  return (
    <>
      {width > 0 && (
        <div
          className="sectionStatistics"
          style={{
            backgroundImage: `url(${config.assetsURL}/${data.backgroundImage[0].slug}?${
              appStore.isMobile ? 'width=375&' : ''
            }cache=15552000)`,
          }}
        >
          {!refreshStatistics && (
            <div className="sectionStatistics-inner">
              <div className="sectionStatistics-inner-title">{data.title}</div>
              <div className="sectionStatistics-inner-items">
                {data.items.map((item: any, index: number) => (
                  <div className="sectionStatistics-inner-items-item" key={index}>
                    <div className="sectionStatistics-inner-items-item-numberGroup">
                      <div className="sectionStatistics-inner-items-item-numberGroup-arrow">&gt;</div>
                      <div className="sectionStatistics-inner-items-item-numberGroup-number">
                        <AnimatedNumbers
                          animateToNumber={item.number}
                          configs={[{mass: 1, tension: 140, friction: 126}]}
                        />
                      </div>
                    </div>
                    <div className="sectionStatistics-inner-items-item-title">{item.title}</div>
                    <div className="sectionStatistics-inner-items-item-subtitle">{item.subtitle}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
