import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';

import $appStore from '../../../store/app/store';
import config from '../../../config/config.json';

import {Ribbon} from '../../../components/Button/Ribbon';

import './SectionExpertise.scss';
import useWindowSize from '../../../hooks/useWindowSize';

interface ISectionExpertise {
  data: any;
}
export const SectionExpertise = (props: ISectionExpertise) => {
  const {data} = props;
  const appStore = useStore($appStore);
  const {width} = useWindowSize();

  return (
    <div
      className="sectionExpertise"
      style={{
        backgroundImage:
          width > 1024
            ? `url(${config.assetsURL}/${data.backgroundImage[0].slug}?width=${width}&cache=15552000)`
            : 'none',
      }}
    >
      <div className="sectionExpertise-container">
        <h1 className="sectionExpertise-title">{data.title}</h1>
        <h2 className="sectionExpertise-text">{data.text}</h2>
        <div className="sectionExpertise-items">
          {data.items.map((item: any, index: number) => (
            <Link
              to={`/${appStore.language}/expertise/${item.flatData.friendlyUrl}`}
              className="sectionExpertise-items-item"
              key={index}
            >
              <div className="sectionExpertise-items-item-header">
                <div className="sectionExpertise-items-item-header-title">{item.flatData.boxTitle}</div>
                <div className="sectionExpertise-items-item-header-ribbon">
                  <Ribbon />
                </div>
              </div>
              <div className="sectionExpertise-items-item-texts">
                <div className="sectionExpertise-items-item-texts-leadPhrase">{item.flatData.leadPhrase}</div>
                <div className="sectionExpertise-items-item-texts-text">{item.flatData.text}</div>
              </div>
              <div className="sectionExpertise-items-item-icon">
                <div className="sectionExpertise-items-item-icon-arrow">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
