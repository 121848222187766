export const getExpertiseContentQuery = `
   queryExpertisecontentContents {
    flatData {
      title
      text
      metaTitle
      metaDescription
      backgroundImage {
        slug
      }
      items {
        flatData {
          title
          boxTitle
          leadPhrase
          text
          friendlyUrl
        }
      }
      featuredCaseStudies {
        flatData {
          title
          shortDescription
        }
      }
    }
  }
  queryMarketsContents {
    id
    flatData {
      title
      text
      friendlyUrl
    }
  }
  queryMarketscontentContents {
    flatData {
      title
      leadText
    }
  }
  queryCasestudiesContents {
    flatData {
      markets {
        id
      }
    }
  }

`;

export const getExpertiseDetailQuery = `
  queryExpertiseContents(filter: "data/friendlyUrl/#CURRENT_LANGUAGE# eq '#SLUG#'") {
    id
    flatData {
      title
      text
      metaTitle
      metaDescription
      leadPhraseDetails
      texts {
        style
        text
        image {
          slug
        }
      }
    }
    referencingCasestudiesContents {
      flatData {
        title
        friendlyUrl
      }
    }
  }`;
