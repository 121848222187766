export const getCaseStudiesQuery = `
  queryCasestudiescontentContents {
    flatData {
      title
      text
      metaTitle
      metaDescription
      items {
        flatData {
          archive
          friendlyUrl
          shortDescription
          title
          leadTime

          expertise {
            flatData {
              title
            }
          }
          markets {
            flatData {
              title
            }
          }
        }
      }
    }
  }
`;
export const getCaseStudiesDetailsQuery = `

  queryCasestudiesContents(
     filter: "data/friendlyUrl/#CURRENT_LANGUAGE# eq '#SLUG#'"
  ) {
    flatData {
      title
      archive
      friendlyUrl
      metaTitle
      metaDescription
      texts {
        title
        subtitle
        text
      }
      leadTime
      expertise {
        flatData {
          title
          friendlyUrl
        }
      }
      markets {
        flatData {
          title
          friendlyUrl
        }
      }
    }
    referencingCasestudiescontentContents {
      flatData {
        items {
          flatData {
            friendlyUrl
          }
        }
      }
    }
  }
`;
