import {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {ArticlesIndex} from './components/ArticlesIndex';
import {ArticlesDetails} from './components/ArticlesDetails';
import {setCurrentPage} from '../../store/app/events';

import './Articles.scss';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

interface IArticles {
  archive: boolean;
}

export const Articles = (props: IArticles) => {
  const {archive} = props;
  const appStore = useStore($appStore);
  const {slug} = useParams();

  useEffect(() => {
    setCurrentPage('articles');
  }, []);
  useEffect(() => {
    if (appStore.language === 'en') {
      window.location.href = '/';
    }
  }, [appStore.language]);

  return (
    <div className="articles">
      {!slug ? <ArticlesIndex archive={archive} /> : <ArticlesDetails slug={slug.split('&')[0]} />}
    </div>
  );
};
