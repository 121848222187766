import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {useParams} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {getMarketDetailsQuery} from './graphql/getMarketDetailsContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';
import {RelatedCaseStudies, Vision} from '../../components';
import {setCurrentPage, setMetaDescription, setMetaImage, setMetaTitle} from '../../store/app/events';

import './Markets.scss';
export const Markets = () => {
  const appStore = useStore($appStore);
  const {slug} = useParams();

  const [data, setData] = useState<any>();

  useEffect(() => {
    const getMarket = async () => {
      if (slug) {
        const query = `{
        ${getMarketDetailsQuery.replace('#CURRENT_LANGUAGE#', appStore.language).replace('#SLUG#', slug)}
    }`;
        const market: any = await getContent(
          'POST',
          `api/content/${config.appName}/graphql`,
          {query},
          `${appStore.language}`,
        );
        if (market) {
          setData(market.data.queryMarketsContents[0]);
          setMetaTitle(market.data.queryMarketsContents[0].flatData.metaTitle);
          setMetaDescription(market.data.queryMarketsContents[0].flatData.metaDescription);
        }
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    if (slug !== undefined) getMarket();
  }, [appStore.language, slug]);

  useEffect(() => {
    setCurrentPage('markets');
  }, []);
  return (
    <div className="markets">
      {data && (
        <>
          <pre>{JSON.stringify(data, null, 2)}</pre>
          <div className="markets-inner">
            <div className="markets-top">
              <div className="markets-container">
                <h1 className="markets-title">{data.flatData.title}</h1>
                <h2 className="markets-text" dangerouslySetInnerHTML={{__html: data.flatData.detailShortText}} />
                <div className="markets-sections">
                  {data.flatData.texts &&
                    data.flatData.texts.map((item: any, index: number) => (
                      <div className="markets-sections-item" key={index}>
                        <div className="markets-sections-item-title"></div>
                        <div className="markets-sections-item-texts">
                          <div
                            className="markets-sections-item-texts-text"
                            dangerouslySetInnerHTML={{__html: item.text}}
                          />
                          {item.image && item.image[0] && (
                            <div className="markets-sections-item-texts-image">
                              <img src={`${config.assetsURL}/${item.image[0].slug}`} alt={item.title} />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <RelatedCaseStudies id={data.id} type={'markets'} color={'brown'} />
          <Vision visionMode={'singleItem'} />
        </>
      )}
    </div>
  );
};
