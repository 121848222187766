/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {label} from '../../utils/getLabel';
import {getSlug} from '../../utils/utils';
import {Button} from '../Button';
import config from '../../config/config.json';
import './Footer.scss';
import {setIsModalOpen} from '../../store/app/events';
export const Footer = () => {
  const appStore = useStore($appStore);
  return (
    <footer>
      <div className={`footer-1`}>
        <div className="footer-1-container">
          {appStore.contactSettings && (
            <div className="footer-1-contactInfo">
              <div className="newsletter">
                <Button
                  text={label('newsletter-label', appStore.language)}
                  buttonStyle={`style-8 small`}
                  action={() => setIsModalOpen(true)}
                />
              </div>
              <div
                className="footer-1-contactInfo-address"
                dangerouslySetInnerHTML={{__html: appStore.contactSettings.address}}
              />
              <p>
                <a className="link" href={`mailto: ${appStore.contactSettings.email}`}>
                  {appStore.contactSettings.email}
                </a>
                <br />
                <a className="link" href={`tel:${appStore.contactSettings.phone}`}>
                  +31 (0)88 - 880 1974
                </a>
              </p>
            </div>
          )}
          <Link to="/" className="footer-1-logo" aria-label="Custom management" title="Custom management">
            <svg className="icon">
              <use xlinkHref="#footer-logo" />
            </svg>
            {appStore.anniversaryLogo && (
              <img src={`${config.assetsURL}/${appStore.anniversaryLogo[0].slug}`} className={`anniversaryLogo `} />
            )}
          </Link>
          <div className="footer-1-nav">
            <div className="footer-1-nav-inner">
              {appStore.language === 'nl' &&
                appStore.footerRoutes &&
                appStore.slugs != null &&
                appStore.footerRoutes.map((item: any, index: number) => (
                  <Link
                    to={`/${appStore.language}/${getSlug(item.type, appStore.slugs!)}`}
                    className="footer-1-nav-inner-item"
                    key={index}
                  >
                    {item.itemTitle}
                  </Link>
                ))}
              {appStore.language === 'en' &&
                appStore.footerRoutes &&
                appStore.slugs != null &&
                appStore.footerRoutes
                  .filter((e: any) => e.type !== 'articles' && e.type !== 'cases')
                  .map((item: any, index: number) => (
                    <Link
                      to={`/${appStore.language}/${getSlug(item.type, appStore.slugs!)}`}
                      className="footer-1-nav-inner-item"
                      key={index}
                    >
                      {item.itemTitle}
                    </Link>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div className="footer-2-legal">
          <div className="footer-nav">
            <Link to={`/${appStore.language}/page/${getSlug('privacy', appStore.slugs!)}`} className="footer-nav-item">
              {label('privacy-policy', appStore.language)}
            </Link>
          </div>
          <div className="copyright">{`© ${new Date().getFullYear()} Custom Management B.V. All Rights Reserved`}</div>
        </div>
      </div>
    </footer>
  );
};
