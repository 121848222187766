export const getContactContentQuery = `
queryContactpageContents {
    flatData {
      title
      leadText
      mapTitle
      mapText
      formTitle
      formText
      formMessageResponse
      firstNameLabel
      lastNameLabel
      companyLabel
      phoneLabel
      buttonText
      metaTitle
      metaDescription
    }
  }`;
