/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useStore } from "effector-react";
import { $appStore } from "../store/app/store";
import { Layout } from "../components";
import config from "../config/config.json";
import {
  setAnniversaryLogo,
  setContactSettings,
  setEnableEn,
  setFooterRoutes,
  setIsRoutesLoaded,
  setLanguage,
  setLanguages,
  setRoutes,
  setSlugs,
} from "../store/app/events";
import { getContent } from "../services/httpService";
import { Languages } from "./types";
import { getNavAndSettingsQuery } from "./graphql/getPaths";
import {
  querySlugs,
  queryRedirects200,
  queryRedirects400,
} from "./graphql/getSlugs";
import useEffectOnce from "../hooks/useEffectOnce";
import {
  Home,
  About,
  Team,
  Expertise,
  Page,
  VisionsOnLeadership,
  CaseStudies,
  Articles,
  Contact,
  Markets,
  Clients,
} from "../pages";
import { getSlug } from "../utils/utils";
import { BrowserRouter } from "react-router-dom";

export const AppRoutes = () => {
  const appStore = useStore($appStore);
  const [redirects, setRedirects] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffectOnce(() => {
    const getlanguages = async () => {
      const langArray: any[] = [];
      const data: Languages = await getContent(
        "GET",
        `api/apps/${config.appName}/languages`,
        "",
        ""
      );
      data.items.map((lang: any) =>
        langArray.push({
          iso2Code: lang.iso2Code,
          name: lang.englishName,
          isDefault: lang.isMaster,
        })
      );
      setLanguages(langArray);
    };
    getlanguages();
  });

  useEffect(() => {
    if (appStore.languages) {
      const defaultLocale = appStore.languages.filter(
        (lang: any) => lang.isDefault === true
      );
      const location = window.location.pathname.split("/");
      const localStorageLocale = Array.isArray(location)
        ? JSON.stringify(location[1])
        : localStorage.getItem("locale");
      if (defaultLocale.length === 1) {
        if (!localStorageLocale) {
          setLanguage(defaultLocale[0].iso2Code);
          localStorage.setItem(
            "locale",
            JSON.stringify(defaultLocale[0].iso2Code)
          );
        } else {
          const pathnameArray = window.location.pathname.split("/");

          if (JSON.parse(localStorageLocale) === "nl") {
            setLanguage("nl");
          } else {
            if (pathnameArray[1] === "en") {
              setLanguage("en");
            } else {
              setLanguage("nl");
            }
          }
        }
      }
    }
  }, [appStore.languages]);

  useEffect(() => {
    const getRoutes = async () => {
      const query = `{
    ${querySlugs}
    }`;
      const data: any = await getContent(
        "POST",
        `api/content/${config.appName}/graphql`,
        { query },
        `${appStore.language}`
      );
      const slugList = data.data.querySlugsContents[0].flatData;
      setSlugs({
        about: slugList.aboutSlug,
        articlesArchive: slugList.articlesArchive,
        articles: slugList.articlesSlug,
        cases: slugList.casesSlug,
        casesArchive: slugList.casesArchive,
        clients: slugList.clientsSlug,
        contact: slugList.contactSlug,
        expertise: slugList.expertiseSlug,
        markets: slugList.marketsSlug,
        team: slugList.teamSlug,
        visions: slugList.visionsSlug,
        visionsArchive: slugList.visionsArchive,
        terms: slugList.termsSlug,
        privacy: slugList.privacySlug,
      });
    };
    getRoutes();
  }, [appStore.language]);

  useEffect(() => {
    const getRoutes = async () => {
      const routesArray: any = [];
      const parentsArray: any = [];
      const query = `{
    ${getNavAndSettingsQuery}
    }`;
      const paths: any = await getContent(
        "POST",
        `api/content/${config.appName}/graphql`,
        { query },
        `${appStore.language}`
      );

      if (paths && appStore.slugs) {
        const footerMenu =
          paths.data.queryFootermenuContents[0].flatData.menuItems;

        const headerMenu =
          paths.data.queryHeadermenuorderContents[0].flatData.headerMenuItems;
        let childrensArray: any = [];
        headerMenu.map((item: any) => {
          const parentSlug = getSlug(item.flatData.type, appStore.slugs!);
          if (item.flatData.submenuList === "Custom") {
            item.flatData.menuItems.length > 0 &&
              item.flatData.menuItems.map((child: any) => {
                childrensArray.push({
                  parent: item.flatData.slug,
                  name: child.itemTitle,
                  slug:
                    getSlug(child.type, appStore.slugs!) === "page"
                      ? "page/" + child.slug
                      : getSlug(child.type, appStore.slugs!),
                  type: child.type,
                });
              });
          }
          if (item.flatData.submenuList === "Team") {
            item.flatData.teamList.map((child: any) => {
              childrensArray.push({
                parent: item.flatData.slug,
                name: child.flatData.name,
                slug: child.flatData.friendlyUrl,
                type: "team",
              });
            });
          }
          if (item.flatData.submenuList === "Expertise") {
            item.flatData.expertiseList.map((child: any) => {
              childrensArray.push({
                parent: item.flatData.slug,
                name: child.flatData.title,
                slug: child.flatData.friendlyUrl,
                type: "expertise",
              });
            });
          }

          parentsArray.push({
            slug: parentSlug,
            name: item.flatData.menuTitle,
            items: childrensArray,
            type: item.flatData.type,
          });
          childrensArray = [];
        });
        routesArray.push(parentsArray);
        setRoutes(routesArray.flat(2));
        setFooterRoutes(footerMenu);
        setIsRoutesLoaded(true);
        const contactSettings =
          paths.data.queryContactsettingsContents[0].flatData;
        setContactSettings(contactSettings);
        setAnniversaryLogo(
          paths.data.querySettingsContents[0].flatData.anniversaryLogo
        );
        // if (!paths.data.querySettingsContents[0].flatData.enableEn) {
        //   localStorage.setItem('locale', JSON.stringify('nl'));
        //   setEnableEn(false);
        // }
        setEnableEn(true);
      }
    };
    if (appStore.language && appStore.slugs) {
      getRoutes();
    }
  }, [appStore.language, appStore.slugs]);

  useEffect(() => {
    const getRedirects = async () => {
      let query = `{
    ${queryRedirects200}
    }`;
      const data200: any = await getContent(
        "POST",
        `api/content/${config.appName}/graphql`,
        { query },
        ``
      );

      query = `{
    ${queryRedirects400}
    }`;
      const data400: any = await getContent(
        "POST",
        `api/content/${config.appName}/graphql`,
        { query },
        ``
      );

      setRedirects(
        data200.data.queryRedirectsContents.concat(
          data400.data.queryRedirectsContents
        )
      );
    };
    getRedirects();
  }, [appStore.language]);

  useEffect(() => {
    if (redirects !== null) {
      let newUrl = "";
      const path = location.pathname.replace(/^./, "");
      const url = redirects.filter((e: any) => e.flatData.oldUrl === path);
      if (url.length === 1) {
        if (url[0].flatData.newUrl === "" && url[0].flatData.newUrl === null) {
          newUrl = "";
        } else {
          newUrl = url[0].flatData.newUrl;
        }
        navigate(`/${newUrl}`, { state: { status: 301 } });
      }
    }
  }, [redirects, location.pathname, appStore.language]);

  return (
    <>
      {appStore.slugs && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={`/:language`} element={<Home />} />
            <Route
              path={`/:language/${appStore.slugs.about}`}
              element={<About />}
            />
            <Route
              path={`/:language/${appStore.slugs.expertise}`}
              element={<Expertise />}
            />
            <Route
              path={`/:language/${appStore.slugs.expertise}/:slug`}
              element={<Expertise />}
            />
            <Route
              path={`/:language/${appStore.slugs.team}`}
              element={<Team />}
            />
            <Route
              path={`/:language/${appStore.slugs.team}/:slug`}
              element={<Team />}
            />
            <Route
              path={`/:language/${appStore.slugs.cases}`}
              element={<CaseStudies archive={false} />}
            />
            <Route
              path={`/:language/${appStore.slugs.cases}/:slug`}
              element={<CaseStudies archive={false} />}
            />
            <Route
              path={`/:language/${appStore.slugs.casesArchive}/`}
              element={<CaseStudies archive={true} />}
            />
            <Route
              path={`/:language/${appStore.slugs.casesArchive}/:slug`}
              element={<CaseStudies archive={true} />}
            />
            <Route
              path={`/:language/${appStore.slugs.markets}`}
              element={<Markets />}
            />
            <Route
              path={`/:language/${appStore.slugs.markets}/:slug`}
              element={<Markets />}
            />
            <Route
              path={`/:language/${appStore.slugs.articles}`}
              element={<Articles archive={false} />}
            />
            <Route
              path={`/:language/${appStore.slugs.articles}/:slug`}
              element={<Articles archive={false} />}
            />
            <Route
              path={`/:language/${appStore.slugs.articlesArchive}/`}
              element={<Articles archive={true} />}
            />
            <Route
              path={`/:language/${appStore.slugs.articlesArchive}/:slug`}
              element={<Articles archive={true} />}
            />
            <Route
              path={`/:language/${appStore.slugs.visions}`}
              element={<VisionsOnLeadership />}
            />
            <Route
              path={`/:language/${appStore.slugs.visions}/:slug`}
              element={<VisionsOnLeadership />}
            />
            <Route
              path={`/:language/${appStore.slugs.clients}/`}
              element={<Clients />}
            />
            <Route
              path={`/:language/${appStore.slugs.contact}/`}
              element={<Contact />}
            />
            <Route path={`/:language/page/:slug`} element={<Page />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      )}
    </>
  );
};
