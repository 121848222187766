import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {getAboutQuery} from './graphql/getAboutContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';

import {Button} from '../../components/Button';
import {FeaturedCaseStudies} from '../../components/FeaturedCaseStudies/FeaturedCaseStudies';
import {setCurrentPage, setMetaDescription, setMetaImage, setMetaTitle} from '../../store/app/events';
import {getSlug} from '../../utils/utils';
import './About.scss';

export const About = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  useEffect(() => {
    const getAbout = async () => {
      const query = `{
    ${getAboutQuery}
    }`;
      const about: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (about) {
        setData(about.data.queryAboutContents[0].flatData);
        setMetaTitle(about.data.queryAboutContents[0].flatData.metaTitle);
        setMetaDescription(about.data.queryAboutContents[0].flatData.metaDescription);
        setMetaImage(`${config.assetsURL}/${about.data.queryAboutContents[0].flatData.aboutImage[0].slug}`);
      }
    };

    getAbout();
  }, []);
  useEffect(() => {
    setCurrentPage('about');
  }, []);
  return (
    <div className="about">
      {data && (
        <div className="about-inner">
          <div
            className="about-top"
            style={{backgroundImage: `url(${config.assetsURL}/${data.backgroundImage[0].slug}?width=1920)`}}
          >
            <div className="about-container">
              <h1 className="about-title">{data.title}</h1>
              <h2 className="about-subtitle">{data.subtitle}</h2>
              <div className="about-text" dangerouslySetInnerHTML={{__html: data.text}} />
              <div className="about-sections">
                <div className="about-sections-item">
                  <div className="about-sections-item-title">{data.aboutTitle}</div>
                  <div className="about-sections-item-texts">
                    <div
                      className="about-sections-item-texts-text"
                      dangerouslySetInnerHTML={{__html: data.aboutText}}
                    />
                    <div className="about-sections-item-texts-image">
                      <img src={`${config.assetsURL}/${data.aboutImage[0].slug}`} alt={data.aboutTitle} />
                    </div>
                  </div>
                </div>
                <div className="about-sections-item">
                  <div className="about-sections-item-title">{data.coreTitle}</div>
                  <div className="about-sections-item-texts">
                    <div className="about-sections-item-texts-text" dangerouslySetInnerHTML={{__html: data.coreText}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {appStore.language === 'nl' && (
            <div className="about-bottom">
              <div className="about-container">
                <div className="about-sections">
                  <div className="about-sections-item">
                    <div className="about-sections-item-title">{data.teamTitle}</div>
                    <div className="about-sections-item-texts-image">
                      <img src={`${config.assetsURL}/${data.teamImage[0].slug}`} alt={data.teamTitle} />
                    </div>
                    <div className="about-sections-item-texts">
                      <div
                        className="about-sections-item-texts-text"
                        dangerouslySetInnerHTML={{__html: data.teamText}}
                      />
                    </div>
                  </div>
                  <div className="about-bottom-button">
                    <Link to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}`}>
                      <Button text={data.teamButtonText} buttonStyle={`style-6`} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <FeaturedCaseStudies />
    </div>
  );
};
