import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {CaseStudiesIndex} from './components/CaseStudiesIndex';
import {CaseStudiesDetails} from './components/CaseStudiesDetails';

import {setCurrentPage} from '../../store/app/events';
import './CaseStudies.scss';

interface ICaseStudies {
  archive: boolean;
}

export const CaseStudies = (props: ICaseStudies) => {
  const {archive} = props;
  const {slug} = useParams();
  useEffect(() => {
    setCurrentPage('caseStudies');
  }, []);
  return (
    <div className="caseStudies">
      {!slug ? <CaseStudiesIndex archive={archive} /> : <CaseStudiesDetails slug={slug} archive={archive} />}
    </div>
  );
};
