import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {getContactContentQuery} from './graphql/getContactContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';
import ContactForm from './ContactForm';
import {Vision} from '../../components';
import {setCurrentPage, setMetaDescription, setMetaImage, setMetaTitle} from '../../store/app/events';
import './Contact.scss';

export const Contact = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getContactContent = async () => {
      const query = `{
    ${getContactContentQuery}
    }`;
      const contact: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (contact) {
        setData(contact.data.queryContactpageContents[0].flatData);
        setMetaTitle(contact.data.queryContactpageContents[0].flatData.metaTitle);
        setMetaDescription(contact.data.queryContactpageContents[0].flatData.metaDescription);
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    getContactContent();
  }, [appStore.language]);

  useEffect(() => {
    setCurrentPage('contact');
  }, []);
  return (
    <div className="contact">
      {data && (
        <div className="contact-container">
          <div className="contact-top">
            <h1 className="contact-top-title">{data.title}</h1>
            <h2 className="contact-top-leadText" dangerouslySetInnerHTML={{__html: data.leadText}} />
          </div>
          <div className="contact-map">
            <div className="contact-map-title">{data.mapTitle}</div>
            <div className="contact-map-text" dangerouslySetInnerHTML={{__html: data.mapText}} />
            <div className="contact-map-iframe">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19558.16574941674!2d5.268292!3d52.211214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe30ba9a65790fa2!2sCustom%20Management%20Interim%20Directeuren!5e0!3m2!1sen!2sgr!4v1671442082054!5m2!1sen!2sgr"
                width="600"
                height="450"
                style={{border: 0}}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Custom Management"
              ></iframe>
            </div>
          </div>
          <ContactForm formTitle={data.formTitle} formText={data.formText} />
        </div>
      )}
      <Vision visionMode="singleItem" />
    </div>
  );
};
