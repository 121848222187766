export const getArticlesQuery = `
  queryArticlescontentContents {
    flatData {
      title
      text
      metaTitle
      metaDescription
    }
  }
  queryArticlesitemsContents(filter: "data/archive/iv eq #ARCHIVE#" orderby: "data/articleDate/iv desc") {
    flatData {
      title
      friendlyUrl
      articleDate
      indexDescription
      author {
        flatData {
          name
          image {
            slug
          }
        }
      }
    }
  }
  `;
export const getArticlesDetailsQuery = `

  queryArticlesitemsContents(
    filter: "data/friendlyUrl/#CURRENT_LANGUAGE# eq '#SLUG#'"
    ) {
      flatData {
        archive
        title
        friendlyUrl
        articleDate
        metaTitle
        metaDescription
        indexDescription
      textSections {
        sectionTitle
        sectionText
      }
      author {
        flatData {
          name
          linkedIn
          phone
          email
          title
          image {
            slug
          }
        }
      }
    }
  }
    queryArticlesitemsContentsWithTotal(filter: "data/archive/iv eq #ARCHIVE#" orderby: "data/articleDate/iv desc") {
      items {
        flatData {
        friendlyUrl

      }
    }
  }
`;
