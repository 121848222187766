export const querySlugs = `
 querySlugsContents {
    flatData {
      aboutSlug
      articlesArchive
      articlesSlug
      casesSlug
      casesArchive
      clientsSlug
      contactSlug
      expertiseSlug
      marketsSlug
      teamSlug
      visionsSlug
      visionsArchive
      termsSlug
      privacySlug
    }
  }

`;
export const queryRedirects200 = `
queryRedirectsContents(top: 200, skip: 0) {
  flatData {
    oldUrl
    newUrl
  }
}`;
export const queryRedirects400 = `
queryRedirectsContents(top: 200, skip: 200) {
  flatData {
    oldUrl
    newUrl
  }
}`;
