export const getHomeContentQuery = `
  queryTeamsectionContents {
    flatData {
      title
      subtitle
      text
      buttonText
      image {
        slug
      }
      backgroundImage {
        slug
      }
      slides {
        title
        subtitle
        text
        buttonText
        buttonLink
        image {
          slug
        }
      }
    }
  }

  queryAboutsectionContents {
    flatData {
      title
      subtitle
      text
      buttonText
      image {
        slug
      }
    }
  }

  queryExpertisesectionContents {
    flatData {
      title
      text
      buttonText
      items {
        flatData {
          title
          boxTitle
          leadPhrase
          friendlyUrl
        }
      }
    }
  }

  queryStatisticssectionContents {
    flatData {
      title
      backgroundImage {
        slug
      }
      items {
        number
        title
        subtitle
      }
    }
  }

  queryContactsectionContents {
    flatData {
      title
      text
      buttonText
      image {
        slug
      }
    }
  }
  querySettingsContents {
    flatData {
      metaTitle
      metaDescription
    }
  }
`;
