import React, {ReactElement, useState} from 'react';
import axios from 'axios';
import {Formik, Form, Field} from 'formik';
import $appStore from '../../store/app/store';

import {Ribbon} from '../../components/Button/Ribbon';
// import {Button} from '../../components/Button/Button';
import {useStore} from 'effector-react';
import {label} from '../../utils/getLabel';

import './ContactForm.scss';

interface IContactForm {
  formTitle: string;
  formText: string;
}

export default function ContactForm(props: IContactForm): ReactElement {
  const {formTitle, formText} = props;

  const appStore = useStore($appStore);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleValidation = (values: any) => {
    const errors: any = {};
    const notRequiredFields = ['company', 'phone'];

    for (const key in values) {
      const value = `${values[key]}`;

      if (!value && !notRequiredFields.includes(key)) {
        errors[key] = label('frmRequired', appStore.language);
      }
    }

    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    const url = `https://cms.greymatter.gr/api/content/custom-management/contactform/`;
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', 'custom-management:default');
    params.append('client_secret', 'kctszq1nmpl42lw4gxiaifxembxj9okeomyj9ii8wryx');
    params.append('scope', 'squidex-api');

    axios({
      method: 'post',
      url: 'https://cms.greymatter.gr/identity-server/connect/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
      },
      data: params,
    })
      .then((response) => {
        const token = response.data.access_token;
        // console.log(values);
        const data = {
          company: values['company'] == '' ? {iv: values['company']} : values['company'],
          email: values['email'],
          firstName: values['firstName'],
          lastName: values['lastName'],
          message: values['message'],
          phone: values['phone'] == '' ? {iv: values['phone']} : values['phone'],
        };
        axios
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            setSubmitting(false);
            if (response) {
              resetForm();
              setIsFormSuccess(true);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="contact-form">
      <div className="contact-form-title">{formTitle}</div>
      {!isFormSuccess && <div className="contact-form-text" dangerouslySetInnerHTML={{__html: formText}} />}
      <div className="ContactForm">
        {isFormSuccess && (
          <div className="ContactForm-success">
            <div className="text" dangerouslySetInnerHTML={{__html: label('fromSuccess', appStore.language)}}></div>
          </div>
        )}
        {!isFormSuccess && (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              message: '',
              company: '',
              phone: '',
            }}
            validate={(values) => {
              return handleValidation(values);
            }}
            onSubmit={(values, {setSubmitting, resetForm}) => {
              onSubmit(values, setSubmitting, resetForm);
            }}
          >
            {({errors, touched, handleChange, isSubmitting}) => (
              <Form id="contact-form" className="form">
                <div className="form-fields">
                  <div className="form-row ">
                    <div className={`form-control ${touched.firstName && errors.firstName ? 'has-error' : ''}`}>
                      <Field
                        type="text"
                        name="firstName['iv']"
                        id="firstName"
                        placeholder={label('frmFName', appStore.language)}
                      />
                      <label htmlFor="firstName">{label('frmFName', appStore.language)}</label>
                      {touched.firstName && errors.firstName && <p className="error-message">{errors.firstName}</p>}
                    </div>

                    <div className={`form-control ${touched.lastName && errors.lastName ? 'has-error' : ''}`}>
                      <Field
                        type="text"
                        name="lastName['iv']"
                        id="lastName"
                        placeholder={label('frmLName', appStore.language)}
                      />
                      <label htmlFor="lastName">{label('frmLName', appStore.language)}</label>
                      {touched.lastName && errors.lastName && <p className="error-message">{errors.lastName}</p>}
                    </div>
                    <div className={`form-control ${touched.email && errors.email ? 'has-error' : ''}`}>
                      <Field type="email" name="email['iv']" id="email" placeholder="Email" />
                      <label htmlFor="email">EMAIL</label>
                      {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                    </div>
                  </div>
                  <div className="form-row ">
                    <div className={`form-control ${touched.company && errors.company ? 'has-error' : ''}`}>
                      <Field
                        type="text"
                        name="company['iv']"
                        id="company"
                        placeholder={label('frmCompany', appStore.language)}
                      />
                      <label htmlFor="company">{label('frmCompany', appStore.language)}</label>
                      {touched.company && errors.company && <p className="error-message">{errors.company}</p>}
                    </div>

                    <div className={`form-control ${touched.phone && errors.phone ? 'has-error' : ''}`}>
                      <Field
                        type="text"
                        name="phone['iv']"
                        id="phone"
                        placeholder={label('frmPhone', appStore.language)}
                      />
                      <label htmlFor="phone">{label('frmPhone', appStore.language)}</label>
                      {touched.phone && errors.phone && <p className="error-message">{errors.phone}</p>}
                    </div>
                  </div>

                  <div className="form-row form-row-message">
                    <div className={`form-control ${touched.message && errors.message ? 'has-error' : ''}`}>
                      <Field as="textarea" name="message['iv']" id="message" />
                      <label htmlFor="message">{label('frmMessage', appStore.language)}</label>
                      {touched.message && errors.message && (
                        <p className="error-message --textarea">{errors.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <button className={`cmButton style-1`} type="submit">
                  <div className="cmButton-text">{label('send-message', appStore.language)}</div>
                  <div className="cmButton-ribbon">
                    <Ribbon />
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
