/* eslint-disable @typescript-eslint/no-non-null-assertion */
import config from '../../../config/config.json';
import {Button} from '../../../components/Button';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';

import $appStore from '../../../store/app/store';
import {getSlug} from '../../../utils/utils';
import './SectionContact.scss';
interface IData {
  data: any;
}

export const SectionContact = (props: IData) => {
  const {data} = props;
  const appStore = useStore($appStore);

  return (
    <div className="sectionContact">
      <div className="sectionContact-inner">
        <div className="sectionContact-inner-image">
          <img
            loading="lazy"
            src={`${config.assetsURL}/${data.image[0].slug}?width=250&cache=15552000`}
            alt={data.title}
          />
        </div>

        <div className="sectionContact-inner-texts">
          <div className="sectionContact-inner-texts-title">{data.title}</div>
          <div className="sectionContact-inner-texts-subtitle">{data.subtitle}</div>
          <div className="sectionContact-inner-texts-text" dangerouslySetInnerHTML={{__html: data.text}} />
          <div className="sectionContact-inner-texts-button">
            <Link to={`/${appStore.language}/${getSlug('contact', appStore.slugs!)}`}>
              <Button text={data.buttonText} buttonStyle="style-4" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
