/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {Button} from '../../../components/Button';
import useWindowSize from '../../../hooks/useWindowSize';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import './SectionExpertise.scss';
import {Grid, Navigation} from 'swiper';
import {getSlug} from '../../../utils/utils';

interface IData {
  data: any;
}

export const SectionExpertise = (props: IData) => {
  const {data} = props;
  const appStore = useStore($appStore);

  const [pages, setPages] = useState<any>();
  const windowSize = useWindowSize();

  useEffect(() => {
    let itemsPerPage = 5;
    if (windowSize.width > 1180) {
      itemsPerPage = 5;
    } else if (windowSize.width > 768) {
      itemsPerPage = 3;
    } else if (windowSize.width > 568) {
      itemsPerPage = 2;
    } else if (windowSize.width < 567) {
      itemsPerPage = 1;
    }

    const totalPages = Math.ceil(data.items.length / itemsPerPage);
    const pagesArray = [];
    for (let i = 0; i < totalPages; i++) {
      const pageItems: any = [];

      data.items.slice(i * itemsPerPage, (i + 1) * itemsPerPage).map((item: any, index: number) => {
        item.isCategory = false;

        pageItems.push(item);
        if (index === itemsPerPage - 1 && windowSize.width > 768) {
          pageItems.push({flatData: {title: 'Our expertise', friendlyUrl: ''}, isCategory: true});
        }
      });
      pagesArray.push({page: i, items: pageItems});
    }
    setPages(pagesArray);
  }, [data.items, windowSize.width]);

  return (
    <div className="sectionExpertise">
      <div className="sectionExpertise-inner">
        <div className="sectionExpertise-inner-topRow">
          <div className="sectionExpertise-inner-topRow-title">{data.title}</div>
          <div
            className="sectionExpertise-inner-topRow-text"
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
          />
        </div>
        <div className="sectionExpertise-inner-expertiseRow">
          <Swiper
            slidesPerView={1}
            effect={'fade'}
            grid={{
              rows: 1,
              fill: 'row',
            }}
            spaceBetween={1}
            speed={800}
            modules={[Grid, Navigation]}
            navigation={true}
            className="mySwiper"
            freeMode={true}
          >
            {pages &&
              pages.map((page: any, index: number) => (
                <SwiperSlide key={index} className="expertiseItems-container">
                  {page.items.map((item: any, index: number) => (
                    <Link
                      to={`/${appStore.language}/${
                        item.isCategory ? getSlug('about', appStore.slugs!) : getSlug('expertise', appStore.slugs!)
                      }/${item.flatData.friendlyUrl}`}
                      className={`sectionExpertise-inner-expertiseRow-item ${item.isCategory ? 'isCategory' : ''}`}
                      key={index}
                      title={`${item.isCategory ? 'Custom management expertise' : item.flatData.boxTitle}`}
                    >
                      {item.isCategory && (
                        <div className="sectionExpertise-inner-expertiseRow-item-icon">
                          <svg className="icon">
                            <use xlinkHref="#logo-icon" />
                          </svg>
                        </div>
                      )}
                      <div className="sectionExpertise-inner-expertiseRow-item-text">
                        <span>{item.flatData.boxTitle}</span>
                        {item.flatData.leadPhrase && (
                          <div className="sectionExpertise-inner-expertiseRow-item-text-leadPhrase">
                            {item.flatData.leadPhrase}
                          </div>
                        )}
                      </div>
                      <div className="sectionExpertise-inner-expertiseRow-item-arrow">
                        <svg className="icon">
                          <use xlinkHref="#arrow-right" />
                        </svg>
                      </div>
                    </Link>
                  ))}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="sectionExpertise-inner-buttonRow">
          <Link to={`/${appStore.language}/${getSlug('expertise', appStore.slugs!)}`}>
            <Button buttonStyle="style-3" text={data.buttonText} />
          </Link>
        </div>
      </div>
    </div>
  );
};
