export const getTeamContentQuery = `
 queryTeamContents {
    flatData {
      title
      subtitle
      text
      metaTitle
      metaDescription
      members {
        id
        flatData {
          referencesText
          referencesImagesWName{
            name
            image {
              slug
            }
          }
          referencesImagesWNameOther{
            name
            image {
              slug
            }
          }
          name
          friendlyUrl
          title
          metaTitle
          metaDescription
          shortText
          text
          linkedIn
          email
          phone
          image {
            slug
          }
        }
      }
    }
  }
`;

export const getTeamArticlesQuery = `
  queryArticlesitemsContents(
    filter: "data/author/iv eq '#ID#'"
    orderby: "data/articleDate/iv desc"
  ) {
    flatData {
      title
      indexDescription
      friendlyUrl
    }
  }
`;
