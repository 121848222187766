export const getClientsQuery = `
queryClientsContents {
    flatData {
      title
      leadText
      text
      metaTitle
      metaDescription
      backgroundImage {
        slug
      }
      clientList {
        name
        image {
          slug
        }
      }
    }
  }`;
