/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import {getCaseStudiesDetailsQuery} from '../graphql/getCaseStudies';
import {getContent} from '../../../services/httpService';
import config from '../../../config/config.json';
import {label} from '../../../utils/getLabel';
import {Vision} from '../../../components';
import {Share} from './Share';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button} from '../../../components/Button';
import {getSlug} from '../../../utils/utils';
import {setMetaTitle, setMetaDescription, setMetaImage} from '../../../store/app/events';
import './CaseStudiesDetails.scss';

interface ICaseStudiesDetails {
  slug: string;
  archive: boolean;
}

export const CaseStudiesDetails = (props: ICaseStudiesDetails) => {
  const {slug, archive} = props;
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [totalCases, setTotalCases] = useState<number>(0);
  const [isArchive, setIsArchive] = useState(archive);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const getExpertiseDetailsContent = async () => {
      const query = `{
        ${getCaseStudiesDetailsQuery
          .replace('#CURRENT_LANGUAGE#', 'nl')
          // .replace('#CURRENT_LANGUAGE#', appStore.language)
          .replace('#SLUG#', slug)}
    }`;
      const caseStudiesDetails: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        'nl', // `${appStore.language}`,
      );

      if (caseStudiesDetails && caseStudiesDetails.data.queryCasestudiesContents) {
        setData(caseStudiesDetails.data.queryCasestudiesContents[0]);
        setMetaTitle(caseStudiesDetails.data.queryCasestudiesContents[0].flatData.metaTitle);
        setMetaDescription(caseStudiesDetails.data.queryCasestudiesContents[0].flatData.metaDescription);
      }
      setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

      setTotalCases(
        caseStudiesDetails.data.queryCasestudiesContents[0].referencingCasestudiescontentContents[0].flatData.items
          .length,
      );
      const currentItem: number =
        caseStudiesDetails.data.queryCasestudiesContents[0].referencingCasestudiescontentContents[0].flatData.items.findIndex(
          (item: any) => item.flatData.friendlyUrl === slug,
        );
      setCurrentIndex(currentItem);
    };

    getExpertiseDetailsContent();
  }, [appStore.language, slug, isArchive, archive]);

  const moveToCase = (moveTo: string) => {
    if (data && slug && (currentIndex > 0 || currentIndex < totalCases)) {
      const cases = data.referencingCasestudiescontentContents[0].flatData.items;
      if (moveTo === 'next') {
        if (currentIndex < totalCases - 1) {
          navigate(
            `/${appStore.language}/${getSlug('cases', appStore.slugs!)}/${
              cases[currentIndex + 1].flatData.friendlyUrl
            }`,
          );
        }
      }
      if (moveTo === 'previous') {
        if (currentIndex > 0) {
          navigate(
            `/${appStore.language}/${getSlug('cases', appStore.slugs!)}/${
              cases[currentIndex - 1].flatData.friendlyUrl
            }`,
          );
        }
      }
    }
  };
  useEffect(() => {
    const casesArchiveLocation = getSlug('cases', appStore.slugs!);
    const casesLocation = getSlug('casesArchive', appStore.slugs!);
    if (location.pathname && casesArchiveLocation && casesLocation) {
      if (location.pathname.includes(casesArchiveLocation)) {
        setIsArchive(true);
      } else if (location.pathname.includes(casesLocation)) {
        setIsArchive(false);
      }
    } else {
      setIsArchive(false);
    }
  }, [location.pathname, appStore.language, archive]);

  return (
    <div className="caseStudiesDetails">
      <div className="caseStudiesDetails-content">
        {data && (
          <div className="caseStudiesDetails-content-container">
            <div className="caseStudiesDetails-content-row caseStudiesDetails-content-header">
              <Link
                to={`/${appStore.language}/${getSlug(archive ? 'casesArchive' : 'cases', appStore.slugs!)}`}
                className="caseStudiesDetails-content-caseStudy"
              >
                <div className="caseStudiesDetails-content-caseStudy-icon">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
                <span>{label(archive ? 'case-studies-archive' : 'case-studies', appStore.language)}</span>
              </Link>
              <div className="caseStudiesDetails-content-share">
                <Share title={data.flatData.title} />
              </div>
            </div>
            <div className="caseStudiesDetails-content-top caseStudiesDetails-content-row">
              <h1 className="caseStudiesDetails-content-top-title">{data.flatData.title}</h1>
              <div className="caseStudiesDetails-content-top-share">
                <Share title={data.flatData.title} />
              </div>
            </div>
            <div className="caseStudiesDetails-content-main caseStudiesDetails-content-row">
              <div className="caseStudiesDetails-content-main-sections">
                {data.flatData.texts &&
                  data.flatData.texts.length > 0 &&
                  data.flatData.texts.map((item: any, index: number) => (
                    <div key={index} className="caseStudiesDetails-content-main-sections-item">
                      {item.title && (
                        <div className="caseStudiesDetails-content-main-sections-item-title">{item.title}</div>
                      )}
                      {item.subtitle && (
                        <div className="caseStudiesDetails-content-main-sections-item-subtitle">{item.subtitle}</div>
                      )}
                      {item.text && (
                        <div
                          className="caseStudiesDetails-content-main-sections-item-text"
                          dangerouslySetInnerHTML={{__html: item.text}}
                        />
                      )}
                    </div>
                  ))}
              </div>
              <div className="caseStudiesDetails-content-main-info">
                <div className="caseStudiesDetails-content-main-info-box">
                  <div className="caseStudiesDetails-content-main-info-box-section">
                    <div className="caseStudiesDetails-content-main-info-box-section-title">
                      {label('title-expertise', appStore.language)}
                    </div>
                    <div className="caseStudiesDetails-content-main-info-box-section-text">
                      {data.flatData.expertise &&
                        data.flatData.expertise.map((expertiseItem: any, index: number) => (
                          <Link
                            className="span"
                            to={`/${appStore.language}/${getSlug('expertise', appStore.slugs!)}/${
                              expertiseItem.flatData.friendlyUrl
                            }`}
                            key={index}
                          >
                            {expertiseItem.flatData.title}
                          </Link>
                        ))}
                    </div>
                  </div>

                  <div className="caseStudiesDetails-content-main-info-box-section">
                    <div className="caseStudiesDetails-content-main-info-box-section-title">
                      {label('lead-time', appStore.language)}
                    </div>
                    <div className="caseStudiesDetails-content-main-info-box-section-text">
                      {data.flatData.leadTime}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="caseStudiesDetails-content-preFooter caseStudiesDetails-content-row">
              <div className="caseStudiesDetails-content-preFooter-ribbon">
                <svg className="icon">
                  <use xlinkHref="#icon-ribbon" />
                </svg>
              </div>

              <div className="caseStudiesDetails-content-preFooter-share">
                <Share title={data.flatData.title} />
              </div>
            </div>
            <div className="caseStudiesDetails-content-footer caseStudiesDetails-content-row">
              <div
                className={`caseStudiesDetails-content-footer-navigation ${currentIndex > 0 ? '' : 'disabled'}`}
                onClick={() => {
                  moveToCase('previous');
                }}
              >
                <div className="caseStudiesDetails-content-footer-navigation-icon previous">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
                <span className="default">{label('previous-case-study', appStore.language)}</span>
                <span className="mobile">{label('previous', appStore.language)}</span>
              </div>

              <div
                className={`caseStudiesDetails-content-footer-navigation ${
                  currentIndex === totalCases - 1 ? 'disabled' : ''
                }`}
                onClick={() => {
                  moveToCase('next');
                }}
              >
                <span className="default">{label('next-case-study', appStore.language)}</span>
                <span className="mobile">{label('next', appStore.language)}</span>{' '}
                <div className="caseStudiesDetails-content-footer-navigation-icon">
                  <svg className="icon">
                    <use xlinkHref="#icon-arrow" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Link
        to={`/${appStore.language}/${getSlug(archive ? 'cases' : 'casesArchive', appStore.slugs!)}`}
        className="caseStudiesIndex-container"
      >
        <div className="caseStudiesIndex-button">
          <Button
            text={label(archive ? 'case-studies' : 'case-studies-archive', appStore.language)}
            buttonStyle={'style-7'}
          />
        </div>
      </Link>
      <Vision visionMode={'singleItem'} />
    </div>
  );
};
