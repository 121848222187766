import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {getHomeContentQuery} from './graphql/getHomeContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';

import {setCurrentPage, setMetaDescription, setMetaTitle, setMetaImage} from '../../store/app/events';
import {SectionAbout, SectionContact, SectionExpertise, SectionTeam, SectionStatistics} from './components';
import './Home.scss';

export const Home = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    const getHomeContent = async () => {
      const query = `{
    ${getHomeContentQuery}
    }`;
      const home: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (home) {
        setData(home.data);
        setMetaImage(`${window.location.href}images/custom-management-logo.png`);
        setMetaTitle(home.data.querySettingsContents[0].flatData.metaTitle);
        setMetaDescription(home.data.querySettingsContents[0].flatData.metaDescription);
      }
    };

    getHomeContent();
  }, [appStore.language]);

  useEffect(() => {
    setCurrentPage('/');
    setData(null);
  }, []);

  return (
    <div className="home">
      {data && appStore.language && appStore.currentPage && (
        <div className="home-inner">
          <SectionTeam data={data.queryTeamsectionContents[0].flatData} />
          <SectionAbout data={data.queryAboutsectionContents[0].flatData} />
          <SectionExpertise data={data.queryExpertisesectionContents[0].flatData} />
          <SectionStatistics data={data.queryStatisticssectionContents[0].flatData} />
          <SectionContact data={data.queryContactsectionContents[0].flatData} />
        </div>
      )}
    </div>
  );
};
