import './Share.scss';
interface IShare {
  title: string;
}
export const Share = (props: IShare) => {
  const {title} = props;
  const openPopup = () => {
    const url = 'https://custommanagement.nl';
    const path = location.pathname.replace('/nl/', '/share/');
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}${path}&title=${title}`,
      'LinkedIn',
      'width=650,height=700',
    );
  };

  return (
    <div className="caseStudyShare" onClick={openPopup}>
      <div className="caseStudyShare-title">share</div>
      <div className="caseStudyShare-icon">
        <svg className="icon">
          <use xlinkHref="#icon-linkedIn-share" />
        </svg>
      </div>
    </div>
  );
};
