export const getPageQuery = `
queryPagesContents(filter:"data/slug/#LANGUAGE# eq '#SLUG#'") {
    flatData {
      slug
      title
      text
      metaTitle
      metaDescription
    }
  }`;
