import {useStore} from 'effector-react';
import {Helmet} from 'react-helmet-async';
import $appStore from '../../store/app/store';

export const Seo = () => {
  const appStore = useStore($appStore);
  // const helmetData = new HelmetData({});
  // const location = useLocation();
  const getStaticProps = () => {
    return {
      props: {
        openGraphData: [
          {
            property: 'og:image',
            content: appStore.metaImage,
            key: 'ogimage',
          },
          {
            property: 'og:url',
            content: window.location.href,
            key: 'ogurl',
          },

          {
            property: 'og:title',
            content: appStore.metaTitle,
            key: 'ogtitle',
          },
          {
            property: 'og:description',
            content: appStore.metaDescription,
            key: 'ogdesc',
          },
          {
            property: 'og:type',
            content: 'website',
            key: 'website',
          },
        ],
      },
    };
  };
  // useEffect(() => {
  //   localStorage.setItem(
  //     'meta',
  //     JSON.stringify({desc: appStore.metaDescription, title: appStore.metaTitle, url: window.location.href}),
  //   );
  // }, [appStore.metaTitle, appStore.metaDescription, window.location.href]);

  return (
    <Helmet>
      {getStaticProps().props.openGraphData.map((og, i: number) => (
        <meta {...og} key={i} />
      ))}
      {/* <meta charSet="utf-8" />
      <title>{appStore.metaTitle}</title>
      <meta property="og:title" content={appStore.metaTitle} data-react-helmet="true" />
      {appStore.metaDescription && (
        <meta property="og:description" content={appStore.metaDescription} data-react-helmet="true" />
      )}
      <meta name="image" property="og:image" content={appStore.metaImage} data-react-helmet="true" />
      <meta property="og:url" content={window.location.href} data-react-helmet="true" /> */}
    </Helmet>
  );
};
