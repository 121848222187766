import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

import {getRelativeCaseStudiesQuery} from './graphql/getRelativeCaseStudies';

import {label} from '../../utils/getLabel';
import {Button} from '../../components/Button';
import config from '../../config/config.json';

import {getContent} from '../../services/httpService';

import {getSlug} from '../../utils/utils';
import './RelatedCaseStudies.scss';

interface IRelatedCaseStudies {
  type: 'expertise' | 'markets';
  id?: string;
  color: string;
}

export const RelatedCaseStudies = (props: IRelatedCaseStudies) => {
  const {type, id, color} = props;
  const appStore = useStore($appStore);
  const [data, setData] = useState<any[]>();

  useEffect(() => {
    const casesArray: any = [];
    const getRelativeCasesContent = async () => {
      const query = `{
    ${getRelativeCaseStudiesQuery.replace('#TYPE#', type)}
    }`;
      const cases: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (cases) {
        cases.data.queryCasestudiesContents.map((item: any) => {
          if (item.flatData[type] && item.flatData[type].length > 0) {
            item.flatData[type].map((v: any) => {
              if (v.id === id) {
                casesArray.push(item);
              }
            });
          }

          setData(casesArray);
        });
      }
    };

    getRelativeCasesContent();
  }, [appStore.language, id, type]);

  return (
    <>
      {data && data.length > 0 && (
        <div className={`relatedCaseStudies ${color}`}>
          <div className="relatedCaseStudies-container">
            <div className={`relatedCaseStudies-title ${color}`}>
              {label('related-case-studies', appStore.language)}
            </div>
            <div className="relatedCaseStudies-items">
              {data.slice(0, 5).map((item: any, index: number) => (
                <Link
                  to={`/${appStore.language}/${getSlug('cases', appStore.slugs!)}/${item.flatData.friendlyUrl}`}
                  className={`relatedCaseStudies-items-item ${color}`}
                  key={index}
                >
                  {item.flatData.title}
                </Link>
              ))}
            </div>
            <Link
              to={`/${appStore.language}/${getSlug('cases', appStore.slugs!)}`}
              className="relatedCaseStudies-button"
            >
              <Button
                text={label('view-all-case-studies', appStore.language)}
                buttonStyle={`${color === 'grey' ? 'style-2' : 'style-8'}`}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
