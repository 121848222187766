/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useState} from 'react';
import config from '../../../config/config.json';
import {Button} from '../../../components/Button';
import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {getParentWidth, getSlug} from '../../../utils/utils';
import useWindowSize from '../../../hooks/useWindowSize';

import './SectionTeam.scss';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

interface IData {
  data: any;
}

export const SectionTeam = (props: IData) => {
  const appStore = useStore($appStore);
  const {data} = props;
  const [teamImageWidth, setTeamImageWidth] = useState<any>(850);
  const [windowWidth, setWindowWidth] = useState<any>();
  const {width} = useWindowSize();

  useEffect(() => {
    const resizeListener = () => {
      const parentWidth = getParentWidth('sectionTeam-inner-image');
      const width = parentWidth ?? 850;
      setTeamImageWidth(width);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    const parentWidth = getParentWidth('sectionTeam-inner-image');
    const width = parentWidth !== null && parentWidth !== undefined ? parentWidth : 683;
    setTeamImageWidth(width);
  }, []);

  useEffect(() => {
    if (width !== windowWidth) {
      setWindowWidth(width);
    }
  }, [windowWidth, width]);

  return (
    <>
      {width > 0 && appStore && (
        <div
          className="sectionTeam"
          style={{
            backgroundImage: `url(${config.assetsURL}/${data.backgroundImage[0].slug}?${
              appStore.isMobile && (width === 0 || width === undefined || width === null) ? 'width=375&' : ''
            }cache=15552000)`,
          }}
        >
          <Swiper navigation={true} modules={[Navigation]} className="mySwiperTeam">
            {data &&
              data.slides.map((slide: any, index: number) => (
                <SwiperSlide key={index} className="sectionTeam-inner">
                  <h1 className="sectionTeam-inner-title">{slide.title}</h1>
                  <Link
                    to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}`}
                    className="sectionTeam-inner-image"
                  >
                    <img
                      loading="lazy"
                      src={`${config.assetsURL}/${slide.image[0].slug}?${
                        appStore.isMobile ? `width=${teamImageWidth}&` : ''
                      }cache=15552000`}
                      alt={data.title}
                    />
                  </Link>
                  <h2 className="sectionTeam-inner-subtitle">{slide.subtitle}</h2>
                  <div className="sectionTeam-inner-text">{slide.text}</div>
                  <Link to={`${slide.buttonLink}`} className="sectionTeam-inner-button">
                    <Button text={slide.buttonText} buttonStyle="style-1" />
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
