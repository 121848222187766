import {AppState} from './types';
import {AppDomain} from './domain';

import {
  setLanguage,
  setLanguages,
  setNavIsOpen,
  setCurrentPage,
  setIsLoading,
  setTitle,
  setDescription,
  setIsMobile,
  setIsRoutesLoaded,
  setRoutes,
  setFooterRoutes,
  setHeaderIsHidden,
  setContactSettings,
  setEnableEn,
  setSlugs,
  setGtm,
  setMetaTitle,
  setMetaDescription,
  setMetaImage,
  setRef,
  setIsModalOpen,
  setAnniversaryLogo,
} from './events';

const initialState: AppState = {
  languages: [],
  language: 'nl',
  navIsOpen: false,
  currentPage: null,
  isLoading: false,
  metaTitle: '',
  metaDescription: '',
  metaImage: '',
  isMobile: false,
  isRoutesLoaded: false,
  allRoutes: null,
  footerRoutes: null,
  headerIsHidden: false,
  enableEn: false,
  contactSettings: {
    address: '',
    email: '',
    phone: '',
    buttonText: '',
  },
  slugs: null,
  gtm: '',
  ref: null,
  isModalOpen: false,
  anniversaryLogo: null,
};

export const $appStore = AppDomain.store<AppState>(initialState)
  .on(setAnniversaryLogo, (state, value) => {
    return {
      ...state,
      anniversaryLogo: value,
    };
  })
  .on(setIsModalOpen, (state, value) => {
    return {
      ...state,
      isModalOpen: value,
    };
  })
  .on(setMetaImage, (state, metaImage) => {
    return {
      ...state,
      metaImage: metaImage,
    };
  })
  .on(setRef, (state, ref) => {
    return {
      ...state,
      ref: ref,
    };
  })
  .on(setMetaDescription, (state, metaDescription) => {
    return {
      ...state,
      metaDescription: metaDescription,
    };
  })
  .on(setMetaTitle, (state, metaTitle) => {
    return {
      ...state,
      metaTitle: metaTitle,
    };
  })
  .on(setGtm, (state, gtm) => {
    return {
      ...state,
      gtm: gtm,
    };
  })
  .on(setSlugs, (state, slugs) => {
    return {
      ...state,
      slugs: slugs,
    };
  })
  .on(setLanguage, (state, language) => {
    localStorage.setItem('locale', JSON.stringify(language));

    return {
      ...state,
      language: language,
    };
  })
  .on(setEnableEn, (state, value) => {
    return {
      ...state,
      enableEn: value,
    };
  })
  .on(setIsMobile, (state, value) => {
    return {
      ...state,
      isMobile: value,
    };
  })
  .on(setContactSettings, (state, value) => {
    return {
      ...state,
      contactSettings: value,
    };
  })
  .on(setHeaderIsHidden, (state, value) => {
    return {
      ...state,
      headeriIsHidden: value,
    };
  })
  .on(setCurrentPage, (state, currentPage) => {
    return {
      ...state,
      currentPage: currentPage,
    };
  })
  .on(setNavIsOpen, (state, value) => {
    if (value === true) {
      document.body.style.overflowY = 'hidden';
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }

    return {
      ...state,
      navIsOpen: value,
    };
  })
  .on(setIsLoading, (state, value) => {
    return {
      ...state,
      isLoading: value,
    };
  })
  .on(setIsRoutesLoaded, (state, value) => {
    return {
      ...state,
      isRoutesLoaded: value,
    };
  })
  .on(setLanguages, (state, languages) => {
    return {
      ...state,
      languages: languages,
    };
  })
  .on(setRoutes, (state, routes) => {
    return {
      ...state,
      allRoutes: routes,
    };
  })
  .on(setFooterRoutes, (state, routes) => {
    return {
      ...state,
      footerRoutes: routes,
    };
  })

  .on(setTitle, (state, value) => {
    return {
      ...state,
      siteTitle: value,
    };
  })
  .on(setDescription, (state, value) => {
    return {
      ...state,
      siteDescription: value,
    };
  });

// $appStore.watch((status) => {
//   console.log(status);
// });

export default $appStore;
