export const Ribbon = () => {
  return (
    <svg id="ribbon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.2 98.4">
      <path className="ribbonColor" d="M56.3,2.1L56.3,2.1c-0.9,0.4-1.7,0.8-2.5,1.3C54.6,2.9,55.5,2.5,56.3,2.1z" />
      <path className="ribbonColor" d="M53.8,3.4l-0.3,0.2L53.8,3.4z" />
      <path className="ribbonColor" d="M40.8,16.6c-5.2,8.6-8.4,20-8.4,32.7C32.4,36.6,35.6,25.2,40.8,16.6z" />
      <path className="ribbonColor" d="M66.8,49.2c0,17-6,32.8-15.7,41.6C60.8,82,66.8,66.2,66.8,49.2z" />
      <polygon className="ribbonColor" points="97.1,4.5 82.8,25.7 97.1,4.6 " />
      <path
        className="ribbonColor"
        d="M87.8,82.7c6-9,9.3-20.8,9.3-33.5V4.6L82.8,25.7c-0.2,0.3-0.5,0.5-0.9,0.5c-0.4,0-0.7-0.2-0.9-0.5L66.8,4.5
	v44.7c0,17-6,32.8-15.7,41.6c4.1,3.5,9.2,5.4,14.6,5.5h0.1C74,96.3,81.8,91.5,87.8,82.7z"
      />
      <path
        className="ribbonColor"
        d="M32.4,49.2c0-12.6,3.2-24,8.4-32.7c3.1-5.3,7.4-9.8,12.6-13l0.3-0.2l0,0c0.8-0.5,1.7-0.9,2.5-1.3h-23
	c-8.2,0-16,4.8-21.9,13.6s-9.3,21-9.3,33.5c0,12.7,3.4,24.7,9.3,33.5s13.7,13.6,22,13.6h23C42.4,90.2,32.4,71.7,32.4,49.2z"
      />
      <path
        className="borderColor"
        d="M98.5,0.1c-0.4-0.2-0.9,0-1.2,0.4L81.9,23.2L66.7,0.5C66.5,0.2,66.2,0,65.8,0H33.4C14.7,0.3,0.1,21.9,0,49.2
	s14.7,48.9,33.4,49.2h32.4c18.7-0.3,33.4-22,33.4-49.2V1.1C99.3,0.6,98.9,0.2,98.5,0.1z M64.7,2.1v47.1c0,16.4-5.9,32-15.2,40.2
	c-2.2-2-4.1-4.3-5.7-6.7c-6-8.9-9.3-20.8-9.3-33.5s3.3-24.5,9.3-33.5C49.3,7.6,56.5,2.8,64,2.2L64.7,2.1z M33.4,96.3
	c-8.2,0-16-4.8-21.9-13.6S2.1,61.9,2.1,49.2c0-12.5,3.4-24.7,9.3-33.5S25.1,2.1,33.3,2.1h23c-0.9,0.4-1.7,0.8-2.6,1.3l0,0l-0.3,0.2
	c-5.2,3.2-9.6,7.7-12.6,13c-5.2,8.6-8.4,20-8.4,32.7c0,22.5,10,41,23.9,47.1L33.4,96.3z M65.7,96.3c-5.3-0.1-10.5-2.1-14.6-5.5
	c9.6-8.8,15.6-24.5,15.7-41.6V4.5l14.2,21.2c0.2,0.3,0.5,0.5,0.9,0.5c0.4,0,0.7-0.2,0.9-0.5L97.1,4.5v44.7c0,12.6-3.3,24.5-9.3,33.5
	C81.8,91.5,74,96.3,65.7,96.3L65.7,96.3z"
      />
    </svg>
  );
};
