import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {getVisionsQuery} from './graphql/getVisions';
import {getContent} from '../../services/httpService';
import $appStore from '../../store/app/store';
import config from '../../config/config.json';

import {Vision} from '../../components';

import './VisionsOnLeadership.scss';
import {setCurrentPage} from '../../store/app/events';

export const VisionsOnLeadership = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getVision = async () => {
      const query = `{
    ${getVisionsQuery}
    }`;
      const visions: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (visions) setData(visions.data.queryVisionsofleadershipcontentContents[0]);
    };

    getVision();
  }, []);
  useEffect(() => {
    setCurrentPage('vision');
  }, []);
  return (
    <div className="visionsOnLeadership">
      {data && (
        <div className="visionsOnLeadership-container">
          <div className="visionsOnLeadership-top">
            <h1 className="visionsOnLeadership-top-title">{data.flatData.title}</h1>
          </div>
          <div className="visionsOnLeadership-text" dangerouslySetInnerHTML={{__html: data.flatData.text}} />
        </div>
      )}
      <Vision visionMode="allItems" items={data && data.flatData.items} />
    </div>
  );
};
