import labels from '../config/labels.json';

const getLabelEn = labels.en.reduce((acc: any, label: any) => {
  Object.keys(label).forEach((key) => {
    acc[key] = label[key];
  });
  return acc;
}, {});

const getLabelEl = labels.nl.reduce((acc: any, label: any) => {
  Object.keys(label).forEach((key) => {
    acc[key] = label[key];
  });
  return acc;
}, {});

export const label = (enumString: string, lang: string) => {
  if (lang === 'en') {
    if (getLabelEn[enumString]) {
      return getLabelEn[enumString];
    } else {
      return enumString;
    }
  } else {
    if (getLabelEl[enumString]) {
      return getLabelEl[enumString];
    } else {
      return enumString;
    }
  }
};
