/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';

import $appStore from '../../store/app/store';
import {setLanguage, setNavIsOpen, setRoutes} from '../../store/app/events';
import {BurgerMenu, Logo} from './components';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import config from '../../config/config.json';
import './Header.scss';

export const Header = () => {
  const appStore = useStore($appStore);
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const [currentLocation, setCurrentLocation] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [headerIsHidden, setHeaderIsHidden] = useState(false);
  const location = useLocation();
  const handleClickOutside = () => {
    setNavIsOpen(false);
  };

  useOnClickOutside(headerRef, handleClickOutside);
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        setNavIsOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      if (window.scrollY > 250) {
        setHeaderIsHidden(true);
        setIsCollapsed(false);
      }
    } else {
      if (window.scrollY > 250) {
        setHeaderIsHidden(false);
        setIsCollapsed(true);
      }
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 100 && currentScrollPos < 251) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
  }, []);

  const sortAlpha = (list: any) => {
    list.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return list;
  };

  const changeLanguage = () => {
    const newLanguage = appStore.languages.filter((item) => item.iso2Code !== appStore.language)[0].iso2Code;
    setRoutes([]);
    navigate(`/${newLanguage}`);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    if (currentLocation == location.key) {
      setIsCollapsed(false);
      setHeaderIsHidden(false);
    }

    setCurrentLocation(location.key);
  }, [location, currentLocation]);
  return (
    <header
      ref={headerRef}
      className={`header ${headerIsHidden ? 'hideHeader' : ''} ${appStore.navIsOpen ? 'isExpanded' : ''} ${
        isCollapsed ? 'isCollapsed' : ''
      } `}
    >
      <div className="menu-right-image">
        <svg className="icon">
          <use xlinkHref="#menu-right-image" />
        </svg>
      </div>
      <Link to="/" className="logo" aria-label="Custom management" title="Custom management">
        <Logo isCollapsed={isCollapsed} />
        {appStore.anniversaryLogo && (
          <img
            src={`${config.assetsURL}/${appStore.anniversaryLogo[0].slug}`}
            className={`anniversaryLogo ${isCollapsed ? 'collapsed' : ''}`}
          />
        )}
      </Link>
      <div className="headerNav">
        <div className="menu-left-image">
          <svg className="icon">
            <use xlinkHref="#menu-left-image" />
          </svg>
        </div>
        {appStore.allRoutes && appStore.language && appStore.slugs && (
          <div className="headerNav-inner">
            <div className="headerNav-inner-column menu-team">
              {appStore.allRoutes
                .filter((route: any) => route.type === 'team')
                .map((parent: any, i: number) => (
                  <div className="headerNav-inner-column-inner" key={i}>
                    <Link to={`/${appStore.language}/${parent.slug}`} className="headerNav-inner-column-inner-title">
                      {parent.name}
                    </Link>
                    <div className="headerNav-inner-column-inner-items">
                      {sortAlpha(parent.items).map((item: any, index: number) => (
                        <Link
                          to={`/${appStore.language}/${parent.slug}/${item.slug}`}
                          className="headerNav-inner-column-inner-items-item"
                          key={index}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="headerNav-inner-column menu-expertise">
              {appStore.allRoutes
                .filter((route: any) => route.type === 'expertise')
                .map((parent: any, j: number) => (
                  <div className="headerNav-inner-column-inner" key={j}>
                    <Link to={`/${appStore.language}/${parent.slug}`} className="headerNav-inner-column-inner-title">
                      {parent.name}
                    </Link>
                    <div className="headerNav-inner-column-inner-items">
                      {parent.items.map((item: any, l: number) => (
                        <Link
                          to={`/${appStore.language}/${parent.slug}/${item.slug}`}
                          className="headerNav-inner-column-inner-items-item"
                          key={l}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>

            <div className="headerNav-inner-column">
              {appStore.allRoutes
                .filter((route: any) => route.type === 'about')
                .map((parent: any, k: number) => (
                  <div className="headerNav-inner-column-inner about-us" key={k}>
                    <Link to={`/${appStore.language}/${parent.slug}`} className="headerNav-inner-column-inner-title">
                      {parent.name}
                    </Link>
                    <div className="headerNav-inner-column-inner-items">
                      {parent.items.map((item: any, index: number) => (
                        <Link
                          to={`/${appStore.language}/${item.slug}`}
                          className="headerNav-inner-column-inner-items-item"
                          key={index}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}

              {appStore.language === 'nl' &&
                appStore.allRoutes
                  .filter((route: any) => route.type === 'articles')
                  .map((parent: any, l: number) => (
                    <div className="headerNav-inner-column-inner menu-publications" key={l}>
                      <Link to={`/${appStore.language}/${parent.slug}`} className="headerNav-inner-column-inner-title">
                        {parent.name}
                      </Link>
                      <div className="headerNav-inner-column-inner-items">
                        {parent.items.map((item: any, index: number) => (
                          <Link
                            to={`/${appStore.language}/${item.slug}`}
                            className="headerNav-inner-column-inner-items-item"
                            key={index}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}

              <div className="headerNav-inner-column-inner bottom">
                {/* {appStore.enableEn && ( */}
                <div className="headerNav-inner-column-inner-languages" onClick={() => changeLanguage()}>
                  <div className="headerNav-inner-column-inner-languages-items">
                    <div className="headerNav-inner-column-inner-languages-items-background"></div>
                    <div className="headerNav-inner-column-inner-languages-items-item">
                      {appStore.languages &&
                        appStore.language &&
                        appStore.languages.filter((item) => item.iso2Code === appStore.language)[0]?.name}
                    </div>
                    <div className="headerNav-inner-column-inner-languages-items-itemRibbon"></div>
                    <div className="headerNav-inner-column-inner-languages-items-item">
                      {appStore.languages &&
                        appStore.language &&
                        appStore.languages.filter((item) => item.iso2Code !== appStore.language)[0]?.name}
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        )}
        <div className="headerNav-inner-column-inner-moto">
          <a
            href="https://www.linkedin.com/company/custom-management/?originalSubdomain=nl"
            aria-label="Custom Management @ LinkedIn"
            className="svg-linkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <svg className="icon">
              <use xlinkHref="#linkedIn-logo" />
            </svg>
          </a>
          <div className="svg-moto">
            <svg className="icon">
              <use xlinkHref="#since" />
            </svg>
          </div>
        </div>
      </div>
      <BurgerMenu />
    </header>
  );
};
