export const getFeaturedVisionQuery = `
queryVisionsofleadershipitemsContents(filter: "data/featured/iv eq true and data/archive/iv eq false") {
    flatData {
      title
      text
      image {
        slug
      }
    }
  }
`;

export const getVisionItemsQuery = `
queryVisionsofleadershipitemsContents(filter: "data/archive/iv eq false") {
    flatData {
      title
      text
      image {
        slug
      }
    }
  }
`;
