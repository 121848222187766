/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../../store/app/store';
import config from '../../../config/config.json';
import './TeamIndex.scss';
import {getSlug} from '../../../utils/utils';

interface ITeamIndex {
  data: any;
}
export const TeamIndex = (props: ITeamIndex) => {
  const {data} = props;
  const appStore = useStore($appStore);
  const sortAlpha = (list: any) => {
    list.sort((a: any, b: any) => a.flatData.name.localeCompare(b.flatData.name));
    return list;
  };
  return (
    <div className="team-container teamIndex">
      <h1 className="teamIndex-title">{data.title}</h1>

      <div className="teamIndex-texts">
        <h2 className="teamIndex-texts-subtitle">{data.subtitle}</h2>
        <div className="teamIndex-texts-text" dangerouslySetInnerHTML={{__html: data.text}} />
        <div className="teamIndex-texts-teamList">
          {sortAlpha(data.members).map((member: any, index: number) => (
            <Link
              to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
              className="teamIndex-texts-teamList-item"
              key={index}
            >
              {member.flatData.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="teamIndex-members">
        {sortAlpha(data.members).map((member: any, index: number) => (
          <div className="teamIndex-members-member" key={index}>
            <div className="teamIndex-members-member-image">
              {member.flatData.image && member.flatData.image.length > 0 && (
                <img
                  src={`${config.assetsURL}/${member.flatData.image[0].slug}?width=427`}
                  alt={member.flatData.name}
                />
              )}
            </div>
            <div className="teamIndex-members-member-texts">
              <Link
                to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                className="teamIndex-members-member-texts-name"
              >
                {member.flatData.name}
              </Link>
              <Link
                to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                className="teamIndex-members-member-texts-title"
              >
                {member.flatData.title}
              </Link>
              <div className="teamIndex-members-member-texts-social">
                <a
                  href={member.flatData.linkedIn}
                  target="_blank"
                  className="teamIndex-members-member-texts-social-icon icon-linkedIn"
                  rel="noreferrer"
                >
                  <svg className="icon">
                    <use xlinkHref="#icon-linkedIn" />
                  </svg>
                </a>
                <a
                  href={`mailto:${member.flatData.email}`}
                  className="teamIndex-members-member-texts-social-icon icon-email"
                >
                  <svg className="icon">
                    <use xlinkHref="#icon-email" />
                  </svg>
                </a>
                <a
                  href={`tel:${member.flatData.phone}`}
                  className="teamIndex-members-member-texts-social-icon icon-phone"
                >
                  <svg className="icon">
                    <use xlinkHref="#icon-phone" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="teamIndex-members-member-card">
              <div className="teamIndex-members-member-card-image">
                {member.flatData.image && member.flatData.image.length && (
                  <img
                    src={`${config.assetsURL}/${member.flatData.image[0].slug}?width=500`}
                    alt={member.flatData.name}
                  />
                )}
              </div>
              <div className="teamIndex-members-member-texts card-texts">
                <Link
                  to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                  className="teamIndex-members-member-texts-name"
                >
                  {member.flatData.name}
                </Link>
                <Link
                  to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                  className="teamIndex-members-member-texts-title"
                >
                  {member.flatData.title}
                </Link>
                <div className="teamIndex-members-member-texts-social">
                  <a
                    href={member.flatData.linkedIn}
                    target="_blank"
                    className="teamIndex-members-member-texts-social-icon icon-linkedIn"
                    rel="noreferrer"
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-linkedIn" />
                    </svg>
                  </a>
                  <a
                    href={`mailto:${member.flatData.email}`}
                    className="teamIndex-members-member-texts-social-icon icon-email"
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-email" />
                    </svg>
                  </a>
                  <a
                    href={`tel:${member.flatData.phone}`}
                    className="teamIndex-members-member-texts-social-icon icon-phone"
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-phone" />
                    </svg>
                  </a>
                </div>

                <Link
                  to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                  className="teamIndex-members-member-texts-text"
                  dangerouslySetInnerHTML={{__html: member.flatData.shortText}}
                />
                <div className="teamIndex-members-member-texts-footer">
                  <div className="teamIndex-members-member-texts-footer-ribbon">
                    <svg className="icon">
                      <use xlinkHref="#icon-ribbon" />
                    </svg>
                  </div>
                  <Link
                    to={`/${appStore.language}/${getSlug('team', appStore.slugs!)}/${member.flatData.friendlyUrl}`}
                    className="teamIndex-members-member-texts-footer-arrow"
                  >
                    <svg className="icon">
                      <use xlinkHref="#icon-arrow" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
