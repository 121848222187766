export const getMarketDetailsQuery = `
queryMarketsContents(filter:"data/friendlyUrl/#CURRENT_LANGUAGE# eq '#SLUG#'") {
    id
    flatData {
      title
      detailShortText
      friendlyUrl
      metaTitle
      metaDescription
      texts {
        text
        image {
          slug
        }
      }
    }
  } `;
