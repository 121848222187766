import {useStore} from 'effector-react';
import {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import $appStore from '../../store/app/store';
import {Autoplay} from 'swiper';
import {label} from '../../utils/getLabel';
import {getSlug, shuffleArray} from '../../utils/utils';
import config from '../../config/config.json';

import 'swiper/css';
import 'swiper/css/autoplay';

import './FeaturedCaseStudies.scss';
import {getContent} from '../../services/httpService';
import {getFeaturedCaseStudiesQuery} from './graphql/getFeaturedCaseStudies';
import {Link} from 'react-router-dom';

export const FeaturedCaseStudies = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getFeatured = async () => {
      const query = `{
    ${getFeaturedCaseStudiesQuery}
    }`;
      const featured: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        'nl', //`${appStore.language}`,
      );

      if (featured) setData(shuffleArray(featured.data.queryCasestudiesContents, true));
    };

    getFeatured();
  }, []);

  return (
    <div className="featuredCaseStudies">
      {data && appStore.language === 'nl' && (
        <div className="featuredCaseStudies-container">
          <div className="featuredCaseStudies-inner">
            <div className="featuredCaseStudies-inner-texts">
              <div className="featuredCaseStudies-inner-texts-title">
                {label('featured-case-studies', appStore.language)}
              </div>
              <Swiper
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                spaceBetween={30}
                speed={800}
                className="mySwiper"
              >
                {data &&
                  data.map((item: any, index: number) => (
                    <SwiperSlide key={index} className="featuredCaseStudies-inner-texts-item">
                      <div className="featuredCaseStudies-inner-texts-subtitle">{item.flatData.title}</div>
                      <div
                        className="featuredCaseStudies-inner-texts-text"
                        dangerouslySetInnerHTML={{__html: item.flatData.shortDescription}}
                      />
                      <Link
                        to={`/${appStore.language}/${getSlug('cases', appStore.slugs!)}/${item.flatData.friendlyUrl}`}
                        className="featuredCaseStudies-inner-texts-button"
                      >
                        {label('read-full-text', appStore.language)}
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
