import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import {Link, useLocation} from 'react-router-dom';
import $appStore from '../../../store/app/store';
import {getArticlesQuery} from '../graphql/getArticles';
import {getContent} from '../../../services/httpService';
import {Button} from '../../../components/Button';
import {label} from '../../../utils/getLabel';
import config from '../../../config/config.json';
import useWindowSize from '../../../hooks/useWindowSize';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {getSlug} from '../../../utils/utils';
import {setMetaDescription, setMetaImage, setMetaTitle} from '../../../store/app/events';

import './ArticlesIndex.scss';
interface IArticles {
  archive: boolean;
}

export const ArticlesIndex = (props: IArticles) => {
  const {archive} = props;

  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  // const [pages, setPages] = useState<any>();
  const [articles, setArticles] = useState<any>();
  // const [isArchive, setIsArchive] = useState(archive);

  const windowSize = useWindowSize();

  useEffect(() => {
    const getArticles = async () => {
      const query = `{
    ${getArticlesQuery.replace('#ARCHIVE#', archive ? 'true' : 'false')}
    }`;
      const articles: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        'nl', //`${appStore.language}`,
      );

      if (articles) {
        setData(articles.data.queryArticlescontentContents[0].flatData);
        setArticles(articles.data.queryArticlesitemsContents);
        setMetaTitle(articles.data.queryArticlescontentContents[0].flatData.metaTitle);
        if (
          articles.data.queryArticlescontentContents.flatData?.metaDescription &&
          articles.data.queryArticlescontentContents.flatData?.metaDescription !== null
        )
          // console.log(articles.data.queryArticlescontentContents.flatData?.metaDescription);
          setMetaDescription(articles.data.queryArticlescontentContents.flatData?.metaDescription);
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    getArticles();
  }, [appStore.language, archive]);

  useEffect(() => {
    if (articles && articles.length > 0) {
      let itemsPerPage = 9;
      if (windowSize.width > 1280) {
        itemsPerPage = 9;
      } else if (windowSize.width > 1024) {
        itemsPerPage = 4;
      } else if (windowSize.width > 768) {
        itemsPerPage = 4;
      } else if (windowSize.width < 767) {
        itemsPerPage = 10;
      }

      const totalPages = articles ? Math.ceil(articles.length / itemsPerPage) : 0;
      const pagesArray = [];
      for (let i = 0; i < totalPages; i++) {
        const pageItems: any = [];

        articles &&
          articles.slice(i * itemsPerPage, (i + 1) * itemsPerPage).map((item: any) => {
            pageItems.push(item);
          });
        pagesArray.push({page: i, items: pageItems});
      }
      // setPages(pagesArray);
    }
  }, [articles, windowSize.width]);

  const formatDate = (articleDate: Date) => {
    const date = new Date(articleDate);
    return new Intl.DateTimeFormat('nl-NL', {year: 'numeric', month: 'long'}).format(date);
  };

  // useEffect(() => {
  //   const articlesArchiveLocation = getSlug('articlesArchive', appStore.slugs!);
  //   const articlesLocation = getSlug('articles', appStore.slugs!);
  //   if (location.pathname && articlesArchiveLocation && articlesLocation) {
  //     if (location.pathname.includes(articlesArchiveLocation)) {
  //       setIsArchive(true);
  //     } else if (location.pathname.includes(articlesLocation)) {
  //       setIsArchive(false);
  //     }
  //   } else {
  //     setIsArchive(false);
  //   }
  // }, [location.pathname, appStore.language, appStore.slugs, archive]);

  return (
    <div className="articlesIndex">
      {data && articles && (
        <>
          <div className="articlesIndex-container">
            <h1 className="articlesIndex-title">
              {archive ? label('articles-archive', appStore.language) : data.title}
            </h1>
            <h2 className="articlesIndex-text" dangerouslySetInnerHTML={{__html: data.text}} />
          </div>

          <div className="articlesIndex-container">
            <div className="articlesIndex-items">
              {articles &&
                articles.length > 0 &&
                articles.map((item: any, index: number) => (
                  <div key={index} className="articlesIndex-items-item">
                    <Link
                      to={`/${appStore.language}/${getSlug(
                        archive ? 'articlesArchive' : 'articles',
                        appStore.slugs!,
                      )}/${item.flatData.friendlyUrl}`}
                      className="articlesIndex-items-item-body"
                    >
                      <div className="articlesIndex-items-item-body-row">
                        {item.flatData.author && item.flatData.author.length === 1 && (
                          <div className="articlesIndex-items-item-body-image">
                            <img
                              src={`${config.assetsURL}/${item.flatData.author[0].flatData.image[0].slug}?width=300`}
                              alt={item.flatData.title}
                            />
                          </div>
                        )}
                        <div className="articlesIndex-items-item-body-texts">
                          <div className="articlesIndex-items-item-body-texts-date">
                            {formatDate(item.flatData.articleDate)}
                          </div>
                          <div className="articlesIndex-items-item-body-texts-title">{item.flatData.title}</div>
                          {item.flatData.author && item.flatData.author.length === 1 && (
                            <div className="articlesIndex-items-item-body-texts-author">
                              by {item.flatData.author[0].flatData.name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="articlesIndex-items-item-body-row">
                        <div
                          className="articlesIndex-items-item-body-shortDescription"
                          dangerouslySetInnerHTML={{__html: item.flatData.indexDescription}}
                        />
                      </div>
                    </Link>
                    <div className="articlesIndex-items-item-footer">
                      <div className="articlesIndex-items-item-footer-ribbon">
                        <svg className="icon">
                          <use xlinkHref="#icon-ribbon" />
                        </svg>
                      </div>
                      <Link
                        to={`/${appStore.language}/${getSlug('articles', appStore.slugs!)}/${
                          item.flatData.friendlyUrl
                        }`}
                        className="articlesIndex-items-item-footer-arrow"
                      >
                        <svg className="icon">
                          <use xlinkHref="#icon-arrow" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      <div className="articlesIndex-container">
        <Link
          to={`/${appStore.language}/${getSlug(archive ? 'articles' : 'articlesArchive', appStore.slugs!)}`}
          className="articlesIndex-button"
        >
          <Button text={label(archive ? 'articles' : 'articles-archive', appStore.language)} buttonStyle={'style-6'} />
        </Link>
      </div>
    </div>
  );
};
