import React, {useEffect, useState} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {getClientsQuery} from './graphql/getClientsContent';
import {getContent} from '../../services/httpService';
import config from '../../config/config.json';

import {FeaturedCaseStudies} from '../../components/FeaturedCaseStudies/FeaturedCaseStudies';
import {setCurrentPage, setMetaDescription, setMetaImage, setMetaTitle} from '../../store/app/events';
import './Clients.scss';

export const Clients = () => {
  const appStore = useStore($appStore);
  const [data, setData] = useState<any>();
  useEffect(() => {
    const getClients = async () => {
      const query = `{
    ${getClientsQuery}
    }`;
      const about: any = await getContent(
        'POST',
        `api/content/${config.appName}/graphql`,
        {query},
        `${appStore.language}`,
      );

      if (about) {
        setData(about.data.queryClientsContents[0].flatData);
        setMetaTitle(about.data.queryClientsContents[0].flatData.metaTitle);
        setMetaDescription(about.data.queryClientsContents[0].flatData.metaDescription);
      }
    };
    setMetaImage(`${window.location.origin}/images/custom-management-logo.png`);

    getClients();
  }, []);
  useEffect(() => {
    setCurrentPage('clients');
  }, []);

  const sortAlpha = (list: any) => {
    list.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return list;
  };

  return (
    <div className="clients">
      {data && (
        <div className="clients-inner">
          <div
            className="clients-top"
            style={{backgroundImage: `url(${config.assetsURL}/${data.backgroundImage[0].slug})`}}
          >
            <div className="clients-container">
              <h1 className="clients-title">{data.title}</h1>
              <h2 className="clients-text" dangerouslySetInnerHTML={{__html: data.leadText}} />
              <div className="clients-sections">
                <div className="clients-sections-item">
                  <div className="clients-sections-item-title"></div>
                  <div className="clients-sections-item-texts">
                    <div className="clients-sections-item-texts-text" dangerouslySetInnerHTML={{__html: data.text}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clients-bottom">
            <div className="clients-container">
              <div className="clients-sections">
                {sortAlpha(data.clientList).map((item: any, index: number) => (
                  <div className="clients-sections-item" key={index}>
                    <img src={`${config.assetsURL}/${item.image[0].slug}?width=155`} alt={item.name} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <FeaturedCaseStudies />
    </div>
  );
};
