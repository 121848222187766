export const getVisionsQuery = `
  queryVisionsofleadershipcontentContents {
    flatData {
      title
      text
      items {
        flatData {
          title
          text
          image {
            slug
          }
        }
      }
    }
  }
`;
