import React from 'react';
import './Logo.scss';

interface ILogo {
  isCollapsed: boolean;
}

export const Logo = (props: ILogo) => {
  const {isCollapsed} = props;

  return (
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.6 130.32">
      <path
        id="logoSymbol"
        fill="currentColor"
        d="M120.38.11a2.49,2.49,0,0,0-2.77,1l-5.76,8.53-5.71-8.51a2.44,2.44,0,0,0-.32-.4l-.07-.06L105.68.6a3.28,3.28,0,0,0-.38-.27l-.13-.06a1.94,1.94,0,0,0-.42-.17l-.11,0a2.6,2.6,0,0,0-.56-.07H88.49c-10.07.17-18,11.42-18,25.63s7.93,25.45,18,25.62h15.6c10.08-.18,18-11.43,18-25.63V2.45A2.42,2.42,0,0,0,120.38.11M101.62,25.63c0,6.52-2,12.72-5.31,16.53a16.84,16.84,0,0,1-1.16-1.52,27.49,27.49,0,0,1-4.15-15,27.54,27.54,0,0,1,4.15-15,12.73,12.73,0,0,1,6.47-5.3Zm-15.55,0c0,8.58,2.91,16.08,7.42,20.71h-5c-3.27,0-6.44-2-8.92-5.7a27.49,27.49,0,0,1-4.15-15,27.54,27.54,0,0,1,4.15-15c2.48-3.67,5.64-5.7,8.93-5.7h5C89,9.54,86.08,17,86.07,25.63m18,20.71h0a8.52,8.52,0,0,1-4-1c4.07-4.75,6.44-11.85,6.46-19.68V10.56l3.24,4.84a2.52,2.52,0,0,0,2,1.09,2.48,2.48,0,0,0,2-1.09l3.3-4.89V25.62a27.37,27.37,0,0,1-4.16,15c-2.49,3.67-5.66,5.7-8.94,5.7"
      />
      <g
        id="text"
        className={`
          ${isCollapsed ? 'hideText' : ''}
        `}
      >
        <g id="custom">
          <path
            fill="currentColor"
            d="M54.31,83.27a3.48,3.48,0,0,1-1.79,3.19,10.81,10.81,0,0,1-5.61,1.17,11,11,0,0,1-5.65-1.17,3.49,3.49,0,0,1-1.8-3.19v-13H35.35V84.05a6.2,6.2,0,0,0,3.18,5.34,15.36,15.36,0,0,0,8.38,2,15.07,15.07,0,0,0,8.32-2,6.15,6.15,0,0,0,3.18-5.34V70.27h-4.1Z"
          />
          <path
            fill="currentColor"
            d="M91.37,80.18a12,12,0,0,0-5.64-1.91c-.82-.1-2.43-.23-5.38-.44a15.35,15.35,0,0,1-3.87-.57c-1.42-.5-1.42-1.25-1.42-1.5,0-.66.44-1.17,1.34-1.58a11.52,11.52,0,0,1,4.43-.66A18.85,18.85,0,0,1,89.5,75.7l.3.14L92.31,73l-.4-.27a18.87,18.87,0,0,0-10.86-3,14.36,14.36,0,0,0-7.51,1.67,5.37,5.37,0,0,0-2.79,4.75,4.4,4.4,0,0,0,2.51,4.23c1.37.68,3.64,1.13,7,1.35a53.34,53.34,0,0,1,6.64.67c1.39.36,2.06,1,2.06,2.09a2.4,2.4,0,0,1-1.57,2.16,11.83,11.83,0,0,1-5.15.9,18.25,18.25,0,0,1-9.7-2.77l-.32-.2-2.27,3,.32.26c2.84,2.27,6.86,3.42,11.94,3.42,3.51,0,6.25-.6,8.16-1.78a5.83,5.83,0,0,0,3-5.21,4.89,4.89,0,0,0-1.93-4.12"
          />
          <polygon
            fill="currentColor"
            points="102.84 74.09 112.19 74.09 112.19 90.82 116.3 90.82 116.3 74.09 125.66 74.09 125.66 70.27 102.84 70.27 102.84 74.09"
          />
          <path
            fill="currentColor"
            d="M157.59,72.4a14.12,14.12,0,0,0-8.84-2.75,14.3,14.3,0,0,0-8.86,2.75,10.05,10.05,0,0,0-.06,16.26,15.91,15.91,0,0,0,17.78,0,10.1,10.1,0,0,0,0-16.29m-2.42,13.2a9.83,9.83,0,0,1-6.42,2,10,10,0,0,1-6.46-2,6.56,6.56,0,0,1,.06-10.13,9.79,9.79,0,0,1,6.4-2,10,10,0,0,1,6,1.77,6.55,6.55,0,0,1,.41,10.37"
          />
          <path
            fill="currentColor"
            d="M6.62,75.14a10,10,0,0,1,6.53-2.06c3.52,0,6.3,1,8.25,3.15l.14.16,3.65-1.32-.28-.36c-2.72-3.55-6.68-5.35-11.77-5.35a14.33,14.33,0,0,0-8.95,2.77A10.23,10.23,0,0,0,4.13,88.6a14.46,14.46,0,0,0,9,2.74,17.44,17.44,0,0,0,6.63-1.15,13.38,13.38,0,0,0,5-3.76l.27-.31L21.6,84.2l-.18.2a10.43,10.43,0,0,1-8.08,3.23,10.45,10.45,0,0,1-6.75-2,6.78,6.78,0,0,1,0-10.46"
          />
          <polygon
            fill="currentColor"
            points="193.93 71.11 184.76 82.67 175.63 71.11 171.14 71.11 171.14 91.26 175.02 91.26 175.02 75.9 184.73 88.25 194.51 75.9 194.51 91.26 198.4 91.26 198.4 71.11 193.93 71.11"
          />
        </g>
        <g id="management">
          <path
            fill="currentColor"
            d="M37,125.56c-.05-1.48-.1-3.26-.1-5.07h0c-.38,1.58-.87,3.33-1.32,4.78l-1.41,4.81H32.11l-1.24-4.76c-.38-1.46-.76-3.2-1.05-4.83h0c-.05,1.67-.12,3.57-.19,5.11l-.22,4.68H26.93L27.68,118h3.5l1.14,4.13c.38,1.41.73,3,1,4.39h.06c.31-1.42.71-3,1.11-4.42L35.7,118h3.46l.62,12.33H37.22Z"
          />
          <path
            fill="currentColor"
            d="M48,127.13l-.81,3.16H44.45L48,118h3.43l3.63,12.32H52.24l-.9-3.16ZM50.94,125l-.72-2.61c-.19-.73-.4-1.67-.57-2.38h-.06c-.15.73-.34,1.66-.51,2.38L48.37,125Z"
          />
          <path
            fill="currentColor"
            d="M59.24,130.28V118h3.08l2.42,4.52a39,39,0,0,1,1.89,4.22h0c-.16-1.62-.21-3.29-.21-5.15V118h2.42v12.33H66.12l-2.49-4.76a44.27,44.27,0,0,1-2-4.35h-.07c.09,1.65.12,3.38.12,5.4v3.71Z"
          />
          <path
            fill="currentColor"
            d="M77.11,127.15l-.81,3.17H73.57L77.13,118h3.43l3.63,12.33H81.36l-.9-3.17Zm3-2.08-.72-2.62c-.19-.73-.4-1.66-.57-2.37h0c-.16.73-.35,1.66-.52,2.37l-.71,2.62Z"
          />
          <path
            fill="currentColor"
            d="M97.63,129.53a12.47,12.47,0,0,1-3.89.69A6.33,6.33,0,0,1,89,128.58a6.4,6.4,0,0,1-1.64-4.5c0-4.1,2.84-6.44,6.65-6.44a7.89,7.89,0,0,1,3.23.59l-.55,2.25A5.85,5.85,0,0,0,94,120a3.62,3.62,0,0,0-3.86,4c0,2.56,1.52,4,3.67,4a3.84,3.84,0,0,0,1.28-.17v-2.6H93.3V123h4.33Z"
          />
          <polygon
            fill="currentColor"
            points="110.73 125.03 106.44 125.03 106.44 127.95 111.23 127.95 111.23 130.24 103.82 130.24 103.82 117.91 110.99 117.91 110.99 120.2 106.44 120.2 106.44 122.76 110.73 122.76 110.73 125.03"
          />
          <polygon
            fill="currentColor"
            points="166.99 120.3 163.85 120.3 163.85 117.96 172.79 117.96 172.79 120.3 169.62 120.3 169.62 130.29 166.99 130.29 166.99 120.3"
          />
          <path
            fill="currentColor"
            d="M126.91,125.56c0-1.48-.1-3.26-.1-5.07h0c-.38,1.58-.86,3.33-1.31,4.78l-1.42,4.8H122l-1.25-4.75c-.38-1.46-.76-3.2-1-4.83h0c-.06,1.67-.12,3.57-.19,5.1l-.23,4.69h-2.43l.74-12.33h3.5l1.14,4.13c.38,1.41.73,3,1,4.39h.07c.31-1.42.71-3,1.1-4.42l1.23-4.1H129l.62,12.33H127.1Z"
          />
          <polygon
            fill="currentColor"
            points="142.45 125 138.17 125 138.17 127.93 142.95 127.93 142.95 130.22 135.54 130.22 135.54 117.89 142.71 117.89 142.71 120.18 138.17 120.18 138.17 122.74 142.45 122.74 142.45 125"
          />
          <path
            fill="currentColor"
            d="M148.78,130.26V117.94h3.07l2.42,4.51a41.29,41.29,0,0,1,1.9,4.23h0c-.16-1.63-.21-3.3-.21-5.16v-3.58h2.42v12.32h-2.76l-2.49-4.75a44.45,44.45,0,0,1-2-4.36h-.07c.09,1.65.12,3.39.12,5.4v3.71Z"
          />
        </g>
        <g id="lines">
          <path
            id="line"
            fill="currentColor"
            d="M198.21,105H1.38a1.39,1.39,0,0,1,0-2.77H198.21a1.39,1.39,0,1,1,0,2.77"
          />
        </g>
      </g>
    </svg>
  );
};
